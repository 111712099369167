import React from "react";
import { useSelector } from "react-redux";
// import ApplicantHome from "../Role_Applicant/ApplicantHome";

import AdminHome from "../Role_Admin/AdminHome";
import OfficerHome from "../Role_Officer/OfficerHome";
import TeamLeaderHome from "../Role_TeamLeader/TeamLeaderHome";
import AuditorHome from "../Role_Auditor/AuditorHome";

// Disbursement and Withdrawal
// import PLannerHome from "../Role_Planner/PLannerHome";
// import EvaluatorHome from "../Role_Evaluator/EvaluatorHome";
// import ManagerHome from "../Role_Manager/ManagerHome";
// import FinanceHome from "../Role_Finance/FinanceHome";

const Home = () => {
  const { data } = useSelector((store) => store.customer);

  return (
    <React.Fragment>
      <>
        {data.groups[0] === "ROLE_ADMIN" && <AdminHome />}
        {data.groups[0] === "ROLE_OFFICER" && <OfficerHome />}
        {data.groups[0] === "ROLE_TEAM_LEADER" && <TeamLeaderHome />}
        {data.groups[0] === "ROLE_AUDITOR" && <AuditorHome />}

        {/* {data.groups[0] === "ROLE_PLANNER" && <PLannerHome />}
        {data.groups[0] === "ROLE_EVALUATOR" && <EvaluatorHome />}
        {data.groups[0] === "ROLE_MANAGER" && <ManagerHome />}
        {data.groups[0] === "ROLE_FINANCE" && <FinanceHome />} */}

        {/*
          {data.groups[0] === "ROLE_APPLICANT" && <ApplicantHome />}
          {data.groups[0] === "ROLE_CLERK" && <ClerkHome />}
          {data.groups[0] === "ROLE_AUTHENTICATOR" && <AuthenticatorHome />}
        */}
      </>
    </React.Fragment>
  );
};

export default Home;
