import React, { useEffect, useRef, useState } from 'react'
import { Button, Image } from 'react-bootstrap';
import { FaPrint } from 'react-icons/fa';
import AmigosLogo from "../Images/new.PNG";
import NewFormInput from '../Role_Officer/NewFormInput';
import { useDispatch, useSelector } from 'react-redux';
import { calculate_principal, calculateMonthlyPay, flat_service_and_life_insurance, life_insurance, Savings_for_loan_deduction } from '../Common/modules/requestForm';
import axiosInstance from '../axiosInstance';
import { setTargetLoan } from '../Common/redux/systemLookups';

function RequestForm({ successnotify, errornotify, targetLoan, targetClient }) {
    const curdate = new Date()
    const { serverIP } = useSelector(store => store.allsettings);
    const printRef = useRef();
    const dispatch = useDispatch();

    // manipulated 
    const [lpsStartedAt, setLpsStartedAt] = useState();
    const [appointmentDate, setAppointmentDate] = useState(0)

    const [approvedPrincipal, setApprovedPrincipal] = useState(0)
    const [age, setAge] = useState(0)
    const [lifein, setLifein] = useState(0)
    const [serviceCharge, setServiceCharge] = useState(0)
    const [annualInterestRate, setAnnualInterestRate] = useState(0)
    const [numberOfRepayments, setNumberOfRepayments] = useState(0)

    const [amount_compulsary, setAmount_compulsary] = useState(0)
    const [amount_loansaving, setAmount_loansaving] = useState(0)
    const [amount_share, setAmount_share] = useState(0)
    const [savingsPrcnt, setSavingsPrcnt] = useState(0)

    const [regularSavingAdded, setRegularSavingAdded] = useState(false)
    const [serviceAndInsuranceFlat, setserviceAndInsuranceFlat] = useState(0)

    useEffect(() => {
        const formattedDate = getFormattedDate(Number(appointmentDate));
        setLpsStartedAt(formattedDate);
    }, [appointmentDate])

    useEffect(() => {
        setAppointmentDate(targetLoan.queueTime)
        setApprovedPrincipal(targetLoan.approvedPrincipal)

        const agefromdob = calculateAge(targetClient.dateOfBirth)
        setAge(agefromdob)

        const lis = agefromdob <= 45 ? 2 : agefromdob <= 60 ? 4 : 10;
        setLifein(lis)

        setServiceCharge(targetLoan.prcntServiceCharge)
        setAnnualInterestRate(targetLoan.annualInterestRate)
        setNumberOfRepayments(targetLoan.numberOfRepayments)
        setAmount_compulsary(targetLoan.amount_compulsary)
        setAmount_loansaving(targetLoan.amount_loansaving)
        setAmount_share(targetLoan.amount_share)

        setserviceAndInsuranceFlat(((targetLoan.prcntServiceCharge + lis) * (targetLoan.approvedPrincipal)) / 100)

    }, []);

    function getFormattedDate(daysToAdd) {
        const currentDate = new Date();
        currentDate.setDate(currentDate.getDate() + daysToAdd);

        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        return currentDate.toLocaleDateString('en-US', options);
    }

    const saveLoan = () => {
        axiosInstance
            .patch(`${serverIP}/loan/loans/${targetLoan.id}`, {
                newSavingPrcnt: savingsPrcnt,
                newTotalDueForPeriod: calculateMonthlyPay(false, targetLoan),
                daysToAppointment: appointmentDate
            })
            .then(res => {
                successnotify('የብድር መረጃ ተስተካክሏል!');
                getLoan();
            })
            .catch(err => {
                console.log(err);
                errornotify("የብድር መረጃ አልተስተካከለም");
            });

    };

    const getLoan = () => {
        axiosInstance
            .get(`${serverIP}/loan/loans/${targetLoan.id}`)
            .then(res => {
                dispatch(
                    setTargetLoan({
                        targetLoan: res.data
                    })
                );
            })
            .catch(err => {
                console.log(err);
            });
    };

    const handlePrint = () => {
        const printContents = printRef.current.innerHTML;
        const originalContents = document.body.innerHTML;
        document.body.innerHTML = printContents;
        window.print();
        document.body.innerHTML = originalContents;
    };

    function calculateAge(dateOfBirth) {
        const today = new Date();
        const birthDate = new Date(dateOfBirth);

        let age = today.getFullYear() - birthDate.getFullYear();
        const monthDifference = today.getMonth() - birthDate.getMonth();
        const dayDifference = today.getDate() - birthDate.getDate();

        // Adjust age if the current date is before the birthday in the current year
        if (monthDifference < 0 || (monthDifference === 0 && dayDifference < 0)) {
            age--;
        }
        return age;
    }


    return (
        <div className="p-2 bg-gray-100 min-h-screen" >

            <div className='flex justify-between mb-2'>
                <Button variant="secondary" onClick={saveLoan}> SAVE </Button>
                <button onClick={handlePrint} className="flex items-center px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-400" >
                    <FaPrint className="mr-2" /> Print
                </button>
            </div>

            <div className="bg-white shadow-md rounded-lg overflow-hidden" ref={printRef}>
                {/* Header */}
                <div className="flex w-full p-2">
                    <Image className='h-24 w-3/12' src={AmigosLogo} />
                    <div className='flex flex-col w-8/12 justify-center items-center'>
                        <div className='text-xl font-bold text-orange-500'>
                            አሚጎስ የገንዘብ ቁጠባና ብድር ተቋም
                        </div>
                        <div className='text-xl font-bold text-orange-500'>
                            የብድር መጠየቅያ ቅጽ
                        </div>
                    </div>
                    <Image className='h-24 w-3/12' src={AmigosLogo} />
                </div>

                {/* Data Section */}
                <div className="flex w-full gap-2 p-2">
                    <div className='w-2/3'>
                        <NewFormInput
                            placeh="ሙሉስም"
                            value={targetClient.amDisplayName}
                            onChange={e => (e.target.value)}
                            disabled={true}
                            label="ሙሉስም" />
                        <NewFormInput
                            value={targetClient.mobileNo}
                            onChange={e => (e.target.value)}
                            disabled={true}
                            placeh="አድራሻ /ስልክ "
                            label="አድራሻ /ስልክ " />
                        <NewFormInput
                            placeh="አባል የሆኑበት ቀን (DD/MM/YY)"
                            value={targetClient.memberSince}
                            onChange={e => (e.target.value)}
                            disabled={true}
                            label="አባል የሆኑበት ቀን (DD/MM/YY)" />
                        <NewFormInput
                            placeh="ጥያቄው የቀረበበት ቀን (DD/MM/YY)"
                            disabled={true}
                            value={curdate}
                            label="ጥያቄው የቀረበበት ቀን (DD/MM/YY)" />
                        <NewFormInput
                            placeh="መለያ ቁጥር"
                            label="መለያ ቁጥር"
                            disabled={true}
                            value={targetClient.entityExternalId} />
                        <NewFormInput
                            label={`ተበዳሪ ዕድሜ (${targetClient.dateOfBirth})`}
                            value={age} disabled={true} />
                        <NewFormInput value={amount_compulsary} label="ያላቸው የመደበኛ ቁጠባ መጠን " />
                        <NewFormInput value={amount_loansaving} label="ያላቸው የብድር ቁጠባ መጠን" />
                        <NewFormInput value={amount_share} label="ያላቸው የአክስዮን መጠን" />

                        <div className='flex w-full mb-1'>
                            <label className="flex w-1/2 justify-end items-center mr-2">የብድር ጥያቄው የቁጠባ ስሌት በ%</label>
                            <select className="form-control w-1/2" onChange={e => setSavingsPrcnt(e.target.value)}>
                                <option value="15" selected={targetLoan.newSavingPrcnt == 15}> 15 </option>
                                <option value="20" selected={targetLoan.newSavingPrcnt == 20}> 20 </option>
                                <option value="25" selected={targetLoan.newSavingPrcnt == 25}> 25 </option>
                                <option value="30" selected={targetLoan.newSavingPrcnt == 30}> 30 </option>
                                <option value="35" selected={targetLoan.newSavingPrcnt == 35}> 35 </option>
                                <option value="40" selected={targetLoan.newSavingPrcnt == 40}> 40 </option>
                                <option value="45" selected={targetLoan.newSavingPrcnt == 45}> 45 </option>
                                <option value="50" selected={targetLoan.newSavingPrcnt == 50}> 50 </option>
                            </select>
                        </div>

                        <NewFormInput
                            value={approvedPrincipal}
                            onChange={e => setApprovedPrincipal(e.target.value)}
                            label="የብድር መጠን" />

                        <NewFormInput
                            value={numberOfRepayments}
                            onChange={e => setNumberOfRepayments(e.target.value)}
                            label="የብድር መመለሻ ግዜ(ወር)" />

                        <NewFormInput
                            value={annualInterestRate}
                            onChange={e => setAnnualInterestRate(e.target.value)}
                            label="የብድር ወለድ" />

                        <NewFormInput
                            value={serviceCharge}
                            onChange={e => setServiceCharge(e.target.value)}
                            label="የአግልግሎት ክፍያ በ%" />

                        <NewFormInput
                            label="ብድር ፕሮሰስ የሚጀምሩበት ቀን"
                            value={appointmentDate}
                            onChange={e => setAppointmentDate(e.target.value)} />

                        <div className="form-check flex justify-end">
                            <input
                                className="form-check-input"
                                type="checkbox"
                                checked={regularSavingAdded}
                                onChange={e => {
                                    setRegularSavingAdded(e.target.checked);
                                }}
                            />
                            <label className="pl-4 form-check-label">በመ.ቁጠባ ብድር ይጨመራል</label>
                        </div>

                        <NewFormInput
                            disabled={true}
                            value={life_insurance(targetLoan)}
                            label="የሂወት መድህን ክፍያ %" />

                        <NewFormInput
                            disabled={true}
                            value={flat_service_and_life_insurance(targetLoan)?.toLocaleString()}
                            label="የአግልግሎት እና ኢንሹራንስ ክፍያ" />

                        <NewFormInput
                            disabled={true}
                            value={Savings_for_loan_deduction(targetLoan)}
                            label="ለብድር መቀነሻ የሚሆን ቁጠባ" />

                        <NewFormInput
                            disabled={true}
                            value={calculate_principal(targetLoan)?.toLocaleString()}
                            label="ዋናው ብድር(principal)" />

                    </div>
                    <div className='w-1/3 p-2 bg-slate-400 rounded-md'>
                        <div>
                            <label className="form-label">ቅርንጫፍ </label>
                            <select className="form-control" >
                                <option value="0"> ቅርንጫፍ ይምረጡ </option>
                            </select>
                        </div>
                        <div>
                            <label className="form-label">የብድር አላማ </label>
                            <select className="form-control" >
                                <option value="0"> የብድር አላማ ይምረጡ </option>
                            </select>
                        </div>
                        <div>
                            <label className="form-label">የብድር አይነት</label>
                            <select className="form-control" >
                                <option value="0"> የብድር አይነት ይምረጡ </option>
                            </select>
                        </div>
                        <div>
                            <label className="form-label">የአባልነት ደረጃ </label>
                            <select className="form-control" >
                                <option value="0"> የአባልነት ደረጃ ይምረጡ </option>
                            </select>
                        </div>
                        <div >
                            <label className="form-label">ጾታ</label>
                            <input disabled={true} className="form-control" value={targetClient.gender} />
                        </div>
                        <div>
                            <label className="form-label"> የጋብቻ ሁኔታ</label>
                            <input disabled={true} className="form-control" value={targetClient.isMarried ? "ያገባ " : "ያላገባ "} />
                        </div>
                        <div>
                            <label className="form-label">የትምህርት ደረጃ</label>
                            <select className="form-control" >
                                <option value="0"> የትምህርት ደረጃ ይምረጡ</option>
                            </select>
                        </div>

                        <div className='flex flex-col bg-slate-100 mt-4 items-center justify-center rounded-lg gap-4 py-4'>
                            <div> የብድር ሂደት የሚጀመርበት ቀን </div>
                            <div className='text-xl font-bold'> {lpsStartedAt} </div>
                        </div>

                    </div>

                </div>

                <div className='px-4'>
                    <NewFormInput label="ተበዳሪ እጅላይ የሚደርስ ብር" value={targetLoan.approvedPrincipal?.toLocaleString()} disabled={true} />
                    <NewFormInput label="ወርሃዊ ክፍያ" value={calculateMonthlyPay(false, targetLoan)?.toLocaleString()} disabled={true} />
                    <NewFormInput label="ጠቅላላ የወለድ መጠን" value={targetLoan.totalDueForPeriod?.toLocaleString()} disabled={true} />
                    <NewFormInput label="መቅረብ ያለበት የዋስትና መጠን" disabled={true} />
                </div>

                {/* Footer */}
                <div className="bg-gray-50 p-4 text-center text-sm">
                    ተበዳሪ አስፈላጊ የብድር ሰነዶችን ማሟላት የሚጀምረው የወረፋ ጊዜ ከተጠናቀቀ በኋላ ይሆናል፡፡ ማንኛውም ተበዳሪ የንብረት የግዥ ውል የሚዋዋለው
                    በአሚጎስ ከተገመተ በኋላ ይሆናል፡፡ የብድር አስፈላጊ ሰነዶች ካሟሉ በኋላ ለብድር ውል ዝግጅት /LOAN PROCESS/ ይደረጋል፡፡ ከብድር ውል የመጨረሻ
                    ፊርማ በኋላ 15 የስራ ቀናት ለብድር ኮሚቴ ፊርማ እንዲሁም ለቼክ ዝግጅት /LOAN DISBURSEMENT/ ተበዳሪው ካላቸው የቁጠባ ተቀማጭ ባልተከፈለ (ዋናው)
                    ብድር በየአመቱ 2% የብድር ኢንሹራንስ ለመክፈል ይገደዳሉ፡፡ ይህም የብድር መመለሻ አመቱ ሳይደርስ ብድር የሚመልስ ከሆነ የብድር ኢንሹራንስ የማይቆረጥበት ይሆናል፡፡
                    አመታዊ ኢንሹራንስ ክፍያው 0.5% ከአባሉ አጠቃላይ ተቀማጭ ሂሳብ (አክሲዎን ፣ መደበኛ ቁጠባ ፣ ፍቃደኝነት ቁጠባ) በአመት አንዴ አንድ አባል ብድር ጠይቆ የብድር
                    ቀጠሮ ቀኑ ደርሶ የብድር ሂደት ሳይጀምር 6 ወር ካለፈው ብድሩ ይቋረጣል፡፡
                </div>
            </div>

        </div>
    )
}

export default RequestForm