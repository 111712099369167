import React, { useEffect, useState } from 'react';
import { Badge, ListGroup } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import axiosInstance from '../axiosInstance';

function AdminHome() {
  const { serverIP } = useSelector(store => store.allsettings);
  const [allCheckListTypes, setAllCheckListTypes] = useState([]);
  const [carManufactures, setCarManufactures] = useState([]);
  const [carModels, setCarModels] = useState([]);
  const [homeTypes, setHomeTypes] = useState([]);

  useEffect(() => {
    allChecklistTypes();
    carManufactureYear();
    carModel();
    homeType();
  }, []);

  const carManufactureYear = () => {
    axiosInstance
      .get(`${serverIP}/car_manufacturer/carmanufacture`)
      .then(res => {
        console.log(res.data);
        setCarManufactures(res.data);
      })
      .catch(err => {
        console.log(err);
      });
  };

  const carModel = () => {
    axiosInstance
      .get(`${serverIP}/car_model/cars`)
      .then(res => {
        console.log(res.data);
        setCarModels(res.data);
      })
      .catch(err => {
        console.log(err);
      });
  };

  const homeType = () => {
    axiosInstance
      .get(`${serverIP}/home_type/hometypes`)
      .then(res => {
        console.log(res.data);
        setHomeTypes(res.data);
      })
      .catch(err => {
        console.log(err);
      });
  };

  const allChecklistTypes = () => {
    axiosInstance
      .get(`${serverIP}/checklist_type/checklisttypes`)
      .then(res => {
        console.log(res.data);
        setAllCheckListTypes(res.data);
      })
      .catch(err => {
        console.log(err);
      });
  };

  const ListItem = ({ name, path, count }) => {
    return <ListGroup.Item className="d-flex justify-content-between items-center px-3 py-1">
      <Link to={`/${path}`} className="m-1 me-auto fw-bold">
        {/* Delegation checklist (ውክልና ዝርዝር) */}
        {name}
      </Link>
      <Badge bg="primary" pill>{count}</Badge>
    </ListGroup.Item>
  }

  return (
    <div className="flex gap-1">

      <ListGroup as="ol" numbered className="px-3 py-1 w-1/2">
        {/* <ListItem name="Delegation checklist (ውክልና ዝርዝር)" path="delegation" count="0" /> */}
        <ListGroup.Item className="d-flex justify-content-between items-center px-3 py-1">
          <Link to="/delegation" className="m-1 me-auto fw-bold">
            Delegation checklist (ውክልና ዝርዝር)
          </Link>
          <Badge bg="primary" pill></Badge>
        </ListGroup.Item>

        <ListGroup.Item className="d-flex justify-content-between items-center px-3 py-1">
          <Link to="/single" className="m-1 me-auto fw-bold">
            Single General loan (ያላገባ ተበዳሪ)
          </Link>
          <Badge bg="primary" pill></Badge>
        </ListGroup.Item>
        <ListGroup.Item className="d-flex justify-content-between items-center px-3 py-1">
          <Link to="/married" className="m-1 me-auto fw-bold">
            Married General loan (ያገባ ተበዳሪ)
          </Link>
          <Badge bg="primary" pill></Badge>
        </ListGroup.Item>

        <ListGroup.Item className="d-flex justify-content-between items-center px-3 py-1">
          <Link to="/singlesurety" className="m-1 me-auto fw-bold">
            Single Guarantee (ያላገባ ዋስ)
          </Link>
          <Badge bg="primary" pill></Badge>
        </ListGroup.Item>

        <ListGroup.Item className="d-flex justify-content-between items-center px-3 py-1">
          <Link to="/marriedsurety" className="m-1 me-auto fw-bold">
            Married Guarantee (ያገባ ዋስ)
          </Link>
          <Badge bg="primary" pill></Badge>
        </ListGroup.Item>

        <ListGroup.Item className="d-flex justify-content-between items-center px-3 py-1">
          <Link to="/house" className="m-1 me-auto fw-bold">
            guaranteed by the home (በቤት ዋስትና)
          </Link>
          <Badge bg="primary" pill></Badge>
        </ListGroup.Item>
        <ListGroup.Item className="d-flex justify-content-between items-center px-3 py-1">
          <Link to="/cars" className="m-1 me-auto fw-bold">
            guaranteed by the car (መኪና ዋስትና)
          </Link>
          <Badge bg="primary" pill></Badge>
        </ListGroup.Item>
        <ListGroup.Item className="d-flex justify-content-between items-center px-3 py-1">
          <Link to="/salary" className="m-1 me-auto">
            guaranteed by salary (በደመወዝ ዋስትና)
          </Link>
          <Badge bg="primary" pill></Badge>
        </ListGroup.Item>
        <ListGroup.Item className="d-flex justify-content-between items-center px-3 py-1">
          <Link to="/membership" className="m-1 me-auto">
            guaranteed by the member (በአባል ዋስትና)
          </Link>
          <Badge bg="primary" pill></Badge>
        </ListGroup.Item>
        <ListGroup.Item className="d-flex justify-content-between items-center px-3 py-1">
          <Link to="/share" className="m-1 me-auto">
            guaranteed by stock certificates (በአክሲዮን ሰርተፍኬት ዋስትና)
          </Link>
          <Badge bg="primary" pill></Badge>
        </ListGroup.Item>

        <ListGroup.Item className="d-flex justify-content-between items-center px-3 py-1">
          <Link to="/banks" className="m-1 me-auto">
            Banks (ባንኮች)
          </Link>
          <Badge bg="primary" pill></Badge>
        </ListGroup.Item>
        <ListGroup.Item className="d-flex justify-content-between items-center px-3 py-1">
          <Link to="/country" className="m-1 me-auto">
            Manufactured Country (የተመረተበት አገር)
          </Link>
          <Badge bg="primary" pill></Badge>
        </ListGroup.Item>
        <ListGroup.Item className="d-flex justify-content-between items-center px-3 py-1">
          <Link to="/companytype" className="m-1 me-auto">
            Company Type (የድርጅት አይነት)
          </Link>
          <Badge bg="primary" pill></Badge>
        </ListGroup.Item>


        <ListGroup.Item className="d-flex justify-content-between items-center ">
          <Link to="/carmodel" className="m-1 me-auto">
            Car Model (የመኪና ሞዴል)
          </Link>
          <Badge bg="primary" pill>
            {carModels.length}
          </Badge>
        </ListGroup.Item>

        <ListGroup.Item className="d-flex justify-content-between items-center">
          <Link to="/carmanufactureyear" className="m-1 me-auto">
            CarManufactureYear (የመኪና ምርት ዘመን)
          </Link>
          <Badge bg="primary" pill>
            {carManufactures.length}
          </Badge>
        </ListGroup.Item>

        <ListGroup.Item className="d-flex justify-content-between items-center">
          <Link to="/hometype" className="m-1 me-auto">
            Home Type (የቤት አይነት)
          </Link>
          <Badge bg="primary" pill>
            {homeTypes.length}
          </Badge>
        </ListGroup.Item>

      </ListGroup>

      <ListGroup as="ol" numbered className="px-3 py-1 w-1/2">
        <ListGroup.Item className="d-flex justify-content-between items-center">
          <Link to="/loancommittee" className="m-1 me-auto fw-bold">
            Loan Committee (የብድር ኮሚቴዎች)
          </Link>
        </ListGroup.Item>
        <ListGroup.Item className="d-flex justify-content-between items-center">
          <Link to="/jobposition" className="m-1 me-auto fw-bold">
            Job Position (የስራ ድርሻ)

          </Link>
        </ListGroup.Item>

        <ListGroup.Item className="d-flex justify-content-between items-center">
          <Link to="/loantype" className="m-1 me-auto fw-bold">
            Loan Types ( የብድር አይነት)

          </Link>
        </ListGroup.Item>

        <ListGroup.Item className="d-flex justify-content-between items-center">
          <Link to="/holidays" className="m-1 me-auto fw-bold">
            Holidays (በአላት)
          </Link>
        </ListGroup.Item>

        <ListGroup.Item className="d-flex justify-content-between items-center">
          <Link to="/marriedgp" className="m-1 me-auto fw-bold">
            <div className="fw-bold"> Married Guarantee Person (ያገባ የመታወቅያ / የንብረት ተያዥ) </div>

          </Link>
        </ListGroup.Item>

        <ListGroup.Item className="d-flex justify-content-between items-center">
          <Link to="/singlegp" className="m-1 me-auto">
            <div className="fw-bold"> Single Guarantee Person (ያላገባ የመታወቅያ / የንብረት ተያዥ) </div>
          </Link>
        </ListGroup.Item>


        <ListGroup.Item className="d-flex justify-content-between items-center">
          <Link to="/savingdecipline" className="m-1 me-auto">
            Saving Decipline
          </Link>
        </ListGroup.Item>
        <ListGroup.Item className="d-flex justify-content-between items-center">
          <Link to="/referenceinamigos" className="m-1 me-auto">
            Reference In Amigos
          </Link>
        </ListGroup.Item>
        <ListGroup.Item className="d-flex justify-content-between items-center">
          <Link to="/loanfinancecategory" className="m-1 me-auto">
            loanfinancecategory
          </Link>
        </ListGroup.Item>
        <ListGroup.Item className="d-flex justify-content-between items-center">
          <Link to="/loanfinancedata" className="m-1 me-auto">
            loanfinancedata
          </Link>
        </ListGroup.Item>


        <ListGroup.Item className="d-flex justify-content-between items-center">
          <Link to="/buildingmaterial" className="m-1 me-auto fw-bold">
            home buildingmaterial
          </Link>
        </ListGroup.Item>
        <ListGroup.Item className="d-flex justify-content-between items-center">
          <Link to="/buildingtype" className="m-1 me-auto fw-bold">
            home building type
          </Link>
        </ListGroup.Item>
        <ListGroup.Item className="d-flex justify-content-between items-center">
          <Link to="/floorlocation" className="m-1 me-auto fw-bold">
            home floor location
          </Link>
        </ListGroup.Item>
        <ListGroup.Item className="d-flex justify-content-between items-center">
          <Link to="/fromasphalt" className="m-1 me-auto fw-bold">
            home from asphalt
          </Link>
        </ListGroup.Item>
        <ListGroup.Item className="d-flex justify-content-between items-center">
          <Link to="/location" className="m-1 me-auto fw-bold">
            home location
          </Link>
        </ListGroup.Item>
        <ListGroup.Item className="d-flex justify-content-between items-center">
          <Link to="/neighbour" className="m-1 me-auto fw-bold">
            home neighbour
          </Link>
        </ListGroup.Item>
        <ListGroup.Item className="d-flex justify-content-between items-center">
          <Link to="/physicalappearance" className="m-1 me-auto fw-bold">
            home physical appearance
          </Link>
        </ListGroup.Item>
        <ListGroup.Item className="d-flex justify-content-between items-center">
          <Link to="/setback" className="m-1 me-auto fw-bold">
            home set-back
          </Link>
        </ListGroup.Item>
        <ListGroup.Item className="d-flex justify-content-between items-center">
          <Link to="/use" className="m-1 me-auto fw-bold">
            home use
          </Link>
        </ListGroup.Item>


        <ListGroup.Item className="d-flex text-green-300 justify-content-between items-center">
          <Link to="/regions" className="m-1 me-auto fw-bold">
            Regions
          </Link>
        </ListGroup.Item>
        <ListGroup.Item className="d-flex text-green-300 justify-content-between items-center">
          <Link to="/zones" className="m-1 me-auto fw-bold">
            Zones
          </Link>
        </ListGroup.Item>
        <ListGroup.Item className="d-flex text-green-300 justify-content-between items-center">
          <Link to="/woredas" className="m-1 me-auto fw-bold"> Woredas </Link>
        </ListGroup.Item>

      </ListGroup>

    </div>
  );
}

export default AdminHome;
