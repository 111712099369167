import React from 'react';
import { Document, Font, Page, Text, StyleSheet, View, Image } from '@react-pdf/renderer';
import { PDFViewer } from '@react-pdf/renderer';
import ReportStyle from '../ReportStyle';
import AmigosLogo from "../../Images/new.PNG";
import getEthiopicDate from '../../Common/modules/GregorianToEthiopian';

import TextBoldUnderlined from '../Reusables/TextBoldUnderlined';
import { numberToWords, numberToWordsPercent } from '../../Common/modules/EnNumToWord';
import TextBold from '../Reusables/TextBold';

function AmHomeGuarantee({ loan, home }) {
    const curdate = new Date();
    const etcurrdate = getEthiopicDate(curdate.getDate(), +' ' + curdate.getMonth() + 1, +' ' + curdate.getFullYear());

    return (
        <PDFViewer style={{ width: '100%', height: 700 }}>
            <Document title="የቤት መያዣ">
                <Page style={ReportStyle.body} wrap>
                    {/* <GuaranteeHeader type="የቤት" /> */}
                    <View style={ReportStyle.container}>
                        <Image style={ReportStyle.ReporIimage} src={AmigosLogo} />
                        <View>
                            <View
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center'
                                }}
                            >
                                <Text style={ReportStyle.name}> የመያዣ ውል ስምምነት </Text>
                                <Text style={ReportStyle.name}> የቤት መያዣ </Text>
                            </View>
                        </View>
                        <Image style={ReportStyle.ReporIimage} src={AmigosLogo} />
                    </View>

                    {Object.keys(loan).length > 0 ? (
                        <>
                            {/* የውል ሰጪ እና ውል ተቀባይ ኣድራሻ START */}
                            <View>
                                <Text style={ReportStyle.heading_one}>የውሉ ዓላማ </Text>
                            </View>
                            <View>
                                <View>
                                    {home?.homegp === null ? (
                                        <>
                                            <Text style={ReportStyle.text_normal}>
                                                ይህ የመያዣ ውል ሊደረግ የቻለው በአበዳሪ አሚጎስ የገንዘብ ቁጠባና ብድር ኃ/የተ/ህ/ስ/ማህበር እና በተበዳሪ
                                                {<TextBoldUnderlined text={loan?.customer?.amDisplayName} />} መካከል በ
                                                {<TextBoldUnderlined text={etcurrdate} />} ቀን በተፈጸመ የብድር ውል መነሻ ሲሆን ለብደሩ መመለስ ዋስትና ይሆን ዘንድ
                                                ንብረትነቱ የ {<TextBoldUnderlined text={loan.customer?.amDisplayName} />} የሆነውን በካርታ ቁጥር
                                                {<TextBoldUnderlined text={home.uniquenum} />} የተሰጠበት ቀን{' '}
                                                <TextBoldUnderlined text={home.givenDate} /> በ
                                                {<TextBoldUnderlined text={loan?.customer?.amDisplayName} />} ስም ተመዝግቦ የሚገኝ ቤትና ይዞታ በዋስትናነት
                                                ለማስያዝ ነው፡፡
                                            </Text>
                                        </>
                                    ) : (
                                        <>
                                            <Text style={ReportStyle.text_normal}>
                                                ይህ የመያዣ ውል ሊደረግ የቻለው በአበዳሪ አሚጎስ የገንዘብ ቁጠባና ብድር ኃ/የተ/ህ/ስ/ማህበር እና በተበዳሪ
                                                {<TextBoldUnderlined text={loan?.customer?.amDisplayName} />} መካከል በ
                                                {<TextBoldUnderlined text={etcurrdate} />} ቀን በተፈጸመ የብድር ውል መነሻ ሲሆን ለብደሩ መመለስ ዋስትና ይሆን ዘንድ
                                                ንብረትነቱ የ <TextBoldUnderlined text={home?.homegp} /> የሆነውን ቤት በካርታ ቁጥር
                                                {<TextBoldUnderlined text={home.uniquenum} />} የተሰጠበት ቀን{' '}
                                                <TextBoldUnderlined text={home.givenDate} /> በ
                                                {<TextBoldUnderlined text={home.homegp} />} ስም ተመዝግቦ የሚገኝ ቤትና ይዞታ በዋስትናነት ለማስያዝ ነው፡፡
                                            </Text>
                                        </>
                                    )}
                                </View>
                            </View>

                            {/* የውል ሰጪ እና ውል ተቀባይ ኣድራሻ END */}

                            <View>
                                <Text style={ReportStyle.heading_one}> የዉሉ ዝርዝር </Text>
                            </View>
                            <View>
                                <Text style={ReportStyle.text_normal}>
                                    1. እኔ አቶ/ወ/ሮ
                                    <TextBoldUnderlined
                                        text={`${home.homegp === null ? loan?.customer?.amDisplayName : home.homegp}`}
                                    />
                                    . ንብረትነቱ የእኔ የሆነ ቤት ፣ የቤት ቁጥሩ {<TextBoldUnderlined text={home.houseno} />} የሆነ
                                    {/* {<TextBoldUnderlined text={home.hometype.amName} />}  */}
                                    በመባል የሚጠራ መኖሪያ ቤት/የንግድ ቤት በአበዳሪ አሚጎስ የገንዘብ ቁጠባና
                                    ብድር ኃ/የተ/ህ/ስ/ማህበር {<TextBoldUnderlined text={etcurrdate} />} ቀን በተፈጸመ የብድር ውል መሰረት የተበደርኩትን ገንዘብ ለመመለስ
                                    ዋስትና ይሆን ዘንድ በመያዣነት እንዲያዝ መፍቀዴን በተለመደው ፊርማዬ አረጋግጣለሁ፡፡
                                </Text>
                                <Text style={ReportStyle.text_normal}>
                                    2. እኔም በአበዳሪ አሚጎስ የገንዘብ ቁጠባና ብድር ኃ/የተ/ህ/ስ/ማህበር ከላይ በተራ ቁጥር 1 ስር ዝርዝር መግለጫው የተጠቀሰው ቤት ተበዳሪው ለተበደሩት ገንዘብ
                                    ዋስትና ይሆን ዘንድ በመያዣነት ለመያዝ ተስማምቻለሁ፡፡
                                </Text>
                                <Text style={ReportStyle.text_normal}>
                                    3. ተጠቃሹን ቤት በመያዣነት ከተያዘበት ቀን ጀምሮ ብድሩ ተከፍሎ እስከሚጠናቀቅበት ጊዜ ድረስ እንዳይሸጥ ፣ እንዳይለወጥ በሌላ በማንኛውም ምክንያት ወደ 3ኛ
                                    ወገን እንዳይተላለፍ እና ምንም ዓይነት ጉዳት ሳይደርስበት እየተንከባከብኩ የመጠበቅ ኃላፊነቱን እኔ ተበዳሪው የወሰድኩ መሆኑን በተለመደው ፊርማዬ አረጋግጣለሁ፡፡
                                </Text>
                                <Text style={ReportStyle.text_normal}>
                                    4. ተበዳሪው ምን አልባት የተበደርኩትን ገንዘብ በወቅቱና በጊዜው መክፈል ቢያቅተኝ ፣ በየወሩ መክፈል የሚጠበቅብኝን የብድር ገንዘብ
                                    <TextBoldUnderlined text={loan?.totalDueForPeriod?.toLocaleString()} />
                                    <TextBold text={` (${numberToWords(loan.totalDueForPeriod)}) `} /> ለተከታታይ 2/ሁለት/ወራት ሳልከፍል ብቆይ በአበዳሪ
                                    አሚጎስ የገንዘብ ቁጠባና ብድር ኃ/የተ/ህ/ስ/ማህበር ቤቱን ከሚመለከታቸው የህግ አስከባሪ አካላት ጋር በመሆን ቦታውን ተረክበው ለሚፈልገው ወገን በመሸጥ የብድሩን
                                    ገንዘብ ወስዶ ቀሪ ተመላሽ ካለ እንዲመልስልኝ የተስማማሁ መሆኔን በተለመደው ፊርማዬ አረጋግጣለሁ፡፡
                                </Text>
                                <Text style={ReportStyle.text_normal}>
                                    5. ከላይ በተራ ቁጥር 4 ስር የተገለጸው እንደተጠበቀ ሆኖ ቤት/ይዞታ የተሸጠበት ዋጋ ከብድር ገንዘቡ የሚያንስ ቢሆን አበዳሪው ቀሪውን ገንዘብ ለማስመለስ
                                    በተበዳሪው ላይ ክስ የመመስረትም ሆነ የተለያዩ አማራጮችን በመጠቀም ገንዘቡን የማስመለስ መብቱ በሕግ የተጠበቀ ነው፡፡
                                </Text>
                                <Text style={ReportStyle.text_normal}>
                                    6. እኔ አበዳሪ አሚጎስ የገንዘብ ቁጠባና ብድር ኃ/የተ/ህ/ስ/ማህበር ተበዳሪው ከላይ በተራ ቁጥር 4 ስር በተገለጸው አግባብ የተበደሩትን ገንዘብ በወቅቱ መክፈል
                                    አቅቷቸው ለተከታታይ 2(ሁለት) ወራት ቢያቋርጡ ቤት/ይዞታ ሽጬ ገንዘቡን ለመውሰድና የቀረ ተመላሽ ገንዘብ የሚኖር ቢሆን ለተበዳሪው ለመመለስ ተስማምቻለሁ፡፡
                                </Text>
                                <Text style={ReportStyle.text_normal}>
                                    7. ይህ የመያዣ ውል በቀን {<TextBoldUnderlined text={etcurrdate} />} በግራና ቀኛችን አካል የተፈጸመ የብድር ውል ስምምነት አካል
                                    እንዲሆን ስምምነት አድርገናል፡፡
                                </Text>
                                <Text style={ReportStyle.text_normal}>
                                    እኔም የተበዳሪው ባል/ሚስት በብድር ውሉና በዚህ የመያዣ ውል የተስማማሁና ኃላፊነቱን ለመውሰድ የፈቀድኩ መሆኑን በተለመደው ፊርማዬ አረጋግጣለሁ፡፡
                                </Text>
                                <Text style={ReportStyle.text_normal}>ይህ ውል በፍ/ብ/ሥ/ሥ/ሕ/ቁ 1723/2005 መሰረት በሕግ ፊት የጸና ነው</Text>
                            </View>

                            <View
                                style={{
                                    marginTop: 10,
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'space-between'
                                }}
                            >
                                <View>
                                    <Text style={ReportStyle.text_normal}> የአበዳሪ ስምና ፊርማ </Text>
                                    <Text style={ReportStyle.text_normal}> ____________________________ </Text>
                                </View>
                                <View>
                                    <Text style={ReportStyle.text_normal}> የዋስ ስምና ፊርማ </Text>
                                    <Text style={ReportStyle.text_normal}> ____________________________ </Text>
                                </View>
                                <View>
                                    <Text style={ReportStyle.text_normal}> የተበዳሪ ስምና ፊርማ </Text>
                                    <Text style={ReportStyle.text_normal}> ____________________________ </Text>
                                </View>
                            </View>

                            <View
                                style={{
                                    marginTop: 10,
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'space-between'
                                }}
                            >
                                <View>
                                    <Text style={ReportStyle.text_normal}> የዋስ/ሚስት/ባል ስምና ፊርማ </Text>
                                    <Text style={ReportStyle.text_normal}> ____________________________</Text>
                                </View>
                                <View>
                                    <Text style={ReportStyle.text_normal}> የምስክሮች ስምና ፊርማ </Text>
                                    <Text style={ReportStyle.text_normal}> ____________________________ </Text>
                                    <Text style={ReportStyle.text_normal}> ____________________________ </Text>
                                    <Text style={ReportStyle.text_normal}> ____________________________ </Text>
                                </View>
                                <View>
                                    <Text style={ReportStyle.text_normal}> የተበዳሪ ባል/ሚስት ስምና ፊርማ </Text>
                                    <Text style={ReportStyle.text_normal}> ____________________________ </Text>
                                </View>
                            </View>
                        </>
                    ) : (
                        <></>
                    )}
                    <View
                        style={{
                            textAlign: 'right',
                            position: 'absolute',
                            bottom: 30,
                            right: 35,
                            fontSize: 10
                        }}
                    >
                        <Text style={ReportStyle.text_normal}>
                            ውሉ ያዘጋጀው፦
                            <TextBoldUnderlined text="______________________________" />
                        </Text>
                        <Text style={ReportStyle.text_normal}>ፊርማ: ______________ </Text>
                    </View>
                </Page>
            </Document>
        </PDFViewer>
    )
}

export default AmHomeGuarantee