

function calculate_principal(targetLoan) {
    if (!targetLoan) return 0;
    const principal = targetLoan.approvedPrincipal - (targetLoan.amount_loansaving - (targetLoan.approvedPrincipal * 5 / 100) - ((targetLoan.prcntServiceCharge + targetLoan.prcntLifeInsurance) * (targetLoan.approvedPrincipal)) / 100)

    return Number(principal.toFixed(2));
}

function life_insurance(targetClient) {
    if (!targetClient) return 0;
    const agefromdob = calculateAge(targetClient.dateOfBirth)
    return getValueBasedOnAge(Number(agefromdob));
}


function getValueBasedOnAge(age) {
    if (age <= 45) {
        return 2;
    } else if (age > 45 && age <= 60) {
        return 4;
    } else if (age > 60) {
        return 10;
    }
}


function calculateAge(dateOfBirth) {
    const today = new Date();
    const birthDate = new Date(dateOfBirth);

    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDifference = today.getMonth() - birthDate.getMonth();
    const dayDifference = today.getDate() - birthDate.getDate();

    if (monthDifference < 0 || (monthDifference === 0 && dayDifference < 0)) {
        age--;
    }
    return age;
}

function flat_service_and_life_insurance(targetLoan) {
    const res = ((targetLoan.prcntServiceCharge + life_insurance(targetLoan.customer)) * (targetLoan.approvedPrincipal)) / 100
    return res;
}

function calculateValue(E9, InterestRate, NumberOfMonths, principal) {
    // Helper function to calculate PMT (Payment)
    function PMT(rate, nper, pv) {
        if (rate === 0) {
            return -(pv / nper);
        } else {
            const factor = Math.pow(1 + rate, nper);
            return -(pv * rate * factor) / (factor - 1);
        }
    }

    if (E9) {
        return Math.round((InterestRate / 12) * principal * 100) / 100;
    } else {
        return Math.round(PMT(InterestRate / 12, NumberOfMonths, - principal) * 100) / 100;
    }
}

function calculateMonthlyPay(E9, targetLoan) {
    // Helper function to calculate PMT (Payment)
    function PMT(rate, nper, pv) {
        if (rate === 0) {
            return -(pv / nper);
        } else {
            const factor = Math.pow(1 + rate, nper);
            return -(pv * rate * factor) / (factor - 1);
        }
    }

    if (E9) {
        return Math.round((targetLoan?.annualInterestRate / 1200) * calculate_principal(targetLoan) * 100) / 100;
    } else {
        return Math.round(PMT(targetLoan?.annualInterestRate / 1200, targetLoan?.numberOfRepayments , - calculate_principal(targetLoan)) * 100) / 100;
    }
}

// ለብድር መቀነሻ የሚሆን ቁጠባ
function Savings_for_loan_deduction(targetLoan)  {

    return targetLoan.amount_loansaving - (targetLoan.approvedPrincipal * 5 / 100) - ((targetLoan.prcntServiceCharge + targetLoan.prcntLifeInsurance) * (targetLoan.approvedPrincipal)) / 100
}

module.exports = {
    calculate_principal,
    life_insurance,
    calculateAge,
    flat_service_and_life_insurance,
    calculateMonthlyPay,
    Savings_for_loan_deduction
};
