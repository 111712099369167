import React, { useEffect, useState } from 'react'
import axiosInstance from '../axiosInstance';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { FaCarAlt, FaEye, FaFileAlt } from 'react-icons/fa';
import { Accordion, Alert, Badge, Button, Card, InputGroup, Modal, Table } from 'react-bootstrap';
import { Field, Form, Formik } from 'formik';
import SalarySchema from '../Schema/SalarySchema';
import InputField from '../ReusableFormiks/InputField';
import { setTargetLoan } from '../Common/redux/systemLookups';

function Salary({ successnotify, errornotify }) {
  const { data } = useSelector(store => store.customer);
  const { serverIP } = useSelector(store => store.allsettings);
  const { targetLoan } = useSelector(store => store.systemLookups);
  const dispatch = useDispatch();
  const { loanId } = useParams();

  const [regions, setRegions] = useState([]);
  const [zones, setZones] = useState([]);
  const [woredas, setWoredas] = useState([]);
  const [ddlcompanytype, setddlcompanytype] = useState([]);
  const [editingRecord, setEditingRecord] = useState(null);

  const [showAddModal, setShowAddModal] = useState(false);
  const [modalDelete , setDeleteModal] = useState(false);


  const [targetRecord, setTargetRecord] = useState({})
  const [file, setFile] = useState(null);

  const [show, setShow] = useState(false);
  const baseStyle = "form-control block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 py-1 px-1 text-base"

  useEffect(() => {
    getCompanyTypes();
    getregions();
    getzones();
    getworedas();
  }, []);


  const getCompanyTypes = () => {
    axiosInstance
      .get(`${serverIP}/ddlcompanytype/ddlcompanytypes`)
      .then(res => {
        setddlcompanytype(res.data);
      })
      .catch(err => {
        console.log(err);
      });
  };

  const getregions = () => {
    axiosInstance
      .get(`${serverIP}/regions/regions/activeregions`)
      .then(res => {
        setRegions(res.data);
      })
      .catch(err => {
        console.log(err);
      });
  };

  const getzones = () => {
    axiosInstance
      .get(`${serverIP}/zones/zone`)
      .then(res => {
        setZones(res.data);
      })
      .catch(err => {
        console.log(err);
      });
  };

  const getworedas = () => {
    axiosInstance
      .get(`${serverIP}/woredas/woreda`)
      .then(res => {
        setWoredas(res.data);
      })
      .catch(err => {
        console.log(err);
      });
  };

  const initialValues = {
    empAmName: '',
    grossSalary: '',
    employeeCompany: '',

    companyname: '',
    companyage: '',
    mobileNo: '',

    empPhoneNum: '',
    idNum: '',
    letterNum: '',

    regionId: '',
    zoneId: '',
    woredaId: ''
  };



  const handleSubmit = (values, { resetForm }) => {
    console.log('handle submit')
    console.log(values)

    if (editingRecord) {

      console.log('editingRecord')
    console.log(editingRecord)

      axiosInstance
        .patch(`${serverIP}/collateral_employee/employees/${editingRecord.id}`, {
          empName: values.empAmName,
          empAmName: values.empAmName,
          grossSalary: values.grossSalary,
          loan: loanId,
          employeeCompany: values.employeeCompany,
          companyname: values.companyname,
          companyage: values.companyage,
          mobileNo: values.mobileNo,
          empPhoneNum: values.empPhoneNum,
          idNum: values.idNum,
          letterNum: values.letterNum,

          regionId: values.regionId,
          zoneId: values.zoneId,
          woredaId: values.woredaId
        })
        .then(res => {
          successnotify();
          setShowAddModal(false);
          getLoan();
          console.log(res.data)
        })
        .catch(err => {
          errornotify();
          console.log(err)
        });
      getLoan();

      setEditingRecord(false);
      setShowAddModal(false)

    } else {
      axiosInstance
        .post(`${serverIP}/collateral_employee/employees`, {
          empName: values.empAmName,
          empAmName: values.empAmName,
          grossSalary: values.grossSalary,
          loan: loanId,
          employeeCompany: values.employeeCompany,
          companyname: values.companyname,
          companyage: values.companyage,
          mobileNo: values.mobileNo,
          empPhoneNum: values.empPhoneNum,
          idNum: values.idNum,
          letterNum: values.letterNum,

          regionId: values.regionId,
          zoneId: values.zoneId,
          woredaId: values.woredaId
        })
        .then(res => {
          successnotify();
          setShowAddModal(false);
          // setIsBtnSaveClicked(false)
          getLoan();
          console.log(res.data)
        })
        .catch(err => {
          errornotify();
          console.log(err)
        });
      getLoan();
      setShowAddModal(false)

    }
    setShowAddModal(false)
    setEditingRecord(false)
    resetForm();

  };

  const getLoan = () => {
    axiosInstance
      .get(`${serverIP}/loan/loans/${targetLoan.id}`)
      .then(res => {
        dispatch(
          setTargetLoan({
            targetLoan: res.data
          })
        );
      })
      .catch(err => {
        console.log(err);
      });
  };


  const deleteRecord = () => {
    axiosInstance
      .delete(`${serverIP}/collateral_employee/employees/${targetRecord.id}`)
      .then(res => {
        setDeleteModal(false);
        getLoan();
      })
      .catch(err => {
        console.log(err);
      });
  };

  const reLoadData = async () => {
    try {
      const deleteOldData = await axiosInstance.delete(
        `${serverIP}/collateral_employee_files/employeefiles/deletebyparent/${targetRecord.id}`
      );
    } catch (error) {
    } finally {
      getLoan();
    }
  };

  const loadData = async empId => {
    try {
      const ddlSalaryChecklist = await axiosInstance.get(`${serverIP}/ddlsalary/ddlsalaries`);

      const transformedArray = ddlSalaryChecklist?.data.map(item => ({
        parent: empId,
        user: data.id,
        amDesc: item.amName,
        checkListId: item.id,
        isMandatory: item.isMandatory,
        markValue: item.markValue
      }));

      await axiosInstance.post(`${serverIP}/collateral_employee_files/employeefiles/bulkinsert`, transformedArray);
    } catch (error) {
    } finally {
      getLoan();
    }
  };

  
  function handleUpload(fileid) {
    if (!file) {
      errornotify();
      return;
    }

    const fd = new FormData();
    fd.append('file', file);
    fd.append('fileType', file.type);
    fd.append('fileUrl', file);
    fd.append('isUploaded', true);

    axiosInstance
      .patch(`${serverIP}/collateral_employee_files/employeefiles/${fileid}`, fd, {
        headers: {
          'Custom-Header': 'value'
        }
      })
      .then(res => {
        getLoan();
        successnotify();
      })
      .catch(err => {
        errornotify();
      });
  }

  const handleDeleteFile = fileid => {
    axiosInstance
      .patch(`${serverIP}/collateral_employee_files/employeefiles/${fileid}`, {
        isUploaded: false
      })
      .then(res => {
        successnotify();
        getLoan();
      })
      .catch(err => {
        errornotify();
        console.log(err);
      });
  };



  return (
    <div>
     
      <Alert className="px-3 flex flex-row justify-between py-1 items-center">
        <div style={{ fontSize: 20, fontWeight: 'bold', color: 'GrayText' }}>የመኪና መያዣ</div>
        {data.groups[0] === 'ROLE_OFFICER' && (
          <Button
            onClick={() => {setShowAddModal(true)
              setEditingRecord(false)
            }}
            className="flex flex-row items-center gap-2"
            disabled={targetLoan?.loanstatus?.id !== 1}
          >
            <FaCarAlt /> ጨምር
          </Button>
        )}
      </Alert>


      {/* modal start  */}

     {/* Modal Delete Start  */}
       <Modal show={modalDelete} onHide={() => setDeleteModal(false)}>
         <Modal.Header style={{ color: 'red' }} closeButton>
           <Modal.Title color="red"> አጥፋ </Modal.Title>
         </Modal.Header>
         <Modal.Body>
           Are you sure you wnt to delete <strong> {targetRecord.enName} </strong>?
         </Modal.Body>
         <Modal.Footer>
           <Button onClick={deleteRecord} variant="danger">
             አጥፋ
           </Button>
         </Modal.Footer>
       </Modal>
       {/* Modal Delete End  */}
     
      {/* Modal Show Start  */}
      <Modal size="lg" show={show} onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title> {targetRecord.amDesc} </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <img src={serverIP + targetRecord.fileUrl} style={{ width: '100%', height: '100%' }} />
        </Modal.Body>
      </Modal>
      {/* Modal Show End  */}

      {/* add modal Start  */}
      <Modal size='lg' show={showAddModal} onHide={() => setShowAddModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>  የደመወዝ ዋስትና </Modal.Title>
        </Modal.Header>

        <Formik
          initialValues={editingRecord || initialValues}
          validationSchema={SalarySchema}
          onSubmit={handleSubmit}
          enableReinitialize
        >
          {({ values, errors, touched, handleChange, setFieldValue }) => (
            <Form>
              <Modal.Body className='flex w-full gap-1'>
                <Card className="p-1 w-1/2">
                  <Card.Header>ተቀጣሪ </Card.Header>
                  <InputField
                    label="ሙሉ ስም "
                    name="empAmName"
                    placeholder="ሙሉ ስም"
                    touched={touched.empAmName}
                    errors={errors.empAmName}
                  />
                  <InputField
                    label="ሙሉ ደመወዝ "
                    name="grossSalary"
                    placeholder="ሙሉ ደመወዝ"
                    touched={touched.grossSalary}
                    errors={errors.grossSalary}
                  />
                  <InputField
                    label="ስልክ ቁጥር "
                    name="empPhoneNum"
                    placeholder="ስልክ ቁጥር"
                    touched={touched.empPhoneNum}
                    errors={errors.empPhoneNum}
                  />
                  <InputField
                    label="የመታውቅያ  ቁጥር "
                    name="idNum"
                    placeholder="የመታውቅያ  ቁጥር"
                    touched={touched.idNum}
                    errors={errors.idNum}
                  />

                  {/* address start  */}
                  <div className="mb-1">
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                      Region
                    </label>
                    <Field
                      as="select"
                      name="regionId"
                      onChange={(e) => {
                        handleChange(e);
                        setFieldValue('zoneId', '');
                        setFieldValue('woredaId', '');
                      }}
                      className={baseStyle}
                    >
                      <option value="">Select Region</option>
                      {regions.map(region => (
                        <option key={region.id} value={region.id}>
                          {region.amDescription}
                        </option>
                      ))}
                    </Field>
                  </div>

                  <div className="mb-1">
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                      Zone
                    </label>
                    <Field
                      as="select"
                      name="zoneId"
                      onChange={(e) => {
                        handleChange(e);
                        setFieldValue('woredaId', '');
                      }}
                      disabled={!values.regionId}
                      className={` ${baseStyle} ${!values.regionId ? 'bg-gray-100 cursor-not-allowed' : ''}`}
                    >
                      <option value="">Select Zone</option>
                      {zones
                        .filter(zone => zone.parent == values.regionId)
                        .map(zone => (
                          <option key={zone.id} value={zone.id}>
                            {zone.amDescription}
                          </option>
                        ))}
                    </Field>
                  </div>

                  <div className="mb-1">
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                      Woreda
                    </label>
                    <Field
                      as="select"
                      name="woredaId"
                      disabled={!values.zoneId}
                      className={`${baseStyle} ${!values.zone ? 'bg-gray-100 cursor-not-allowed' : ''}`}
                    >
                      <option value="">Select Woreda</option>
                      {woredas
                        .filter(woreda => woreda.parent == values.zoneId)
                        .map(woreda => (
                          <option key={woreda.id} value={woreda.id}>
                            {woreda.amDescription}
                          </option>
                        ))}
                    </Field>
                  </div>
                  {/* address end   */}



                  <InputField
                    label="የደብዳቤ  ቁጥር "
                    name="letterNum"
                    placeholder="የደብዳቤ  ቁጥር"
                    touched={touched.letterNum}
                    errors={errors.letterNum}
                  />
                </Card>
                <Card className="p-1 w-1/2">

                  <Card.Header>የቀጣሪ ድርጅት </Card.Header>
                  <div className="mb-1">
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                      የድርጅት አይነት
                    </label>
                    <Field
                      as="select"
                      name="employeeCompany"

                      className={baseStyle}
                    >
                      <option value="">የድርጅት አይነት ይምረጡ </option>
                      {ddlcompanytype.map(compt => (
                        <option key={compt.id} value={compt.id}>
                          {compt.amName}
                        </option>
                      ))}
                    </Field>
                  </div>

                  <InputField
                    label="የድርጅት ስም "
                    name="companyname"
                    placeholder="የድርጅት ስም"
                    touched={touched.companyname}
                    errors={errors.companyname}
                  />
                  <InputField
                    label="የድርጅት እድሜ "
                    name="companyage"
                    placeholder="የድርጅት እድሜ"
                    touched={touched.companyage}
                    errors={errors.companyage}
                  />
                  <InputField
                    label="ስልክ ቁጥር "
                    name="mobileNo"
                    placeholder="ስልክ ቁጥር"
                    touched={touched.mobileNo}
                    errors={errors.mobileNo}
                  />

                </Card>
              </Modal.Body>
              <Modal.Footer className='flex justify-end'>

                <Button type="submit" variant={editingRecord ? "warning" : "primary"} >
                  {editingRecord ? 'Update' : 'Save'}
                </Button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>

      </Modal>
      {/* add modal end  */}
      {/* modal end  */}

      {/* accordion end  */}
      <div className="row">
        <div className="col-sm-12" style={{ paddingTop: 5 }}>
          {Object.keys(targetLoan)?.length > 0 ? (
            <Accordion defaultActiveKey="1">
              {targetLoan.collateralemployee.map(sal => {
                return (
                  <Accordion.Item eventKey={sal.id}>
                    <Accordion.Header>
                      <div className="flex flex-row items-center gap-2">
                        <FaFileAlt />{' '}
                        <Badge>
                          {sal.salaryfiles?.filter(x => x.isUploaded === true)?.length}/ {sal.salaryfiles?.length}
                        </Badge>
                        {`${sal.empAmName} @ ${sal.companyname} Gross Salary ${sal.grossSalary?.toLocaleString()}`}
                      </div>
                    </Accordion.Header>
                    <Accordion.Body>
                      <div className="flex flex-row p-2 justify-between bg-slate-500 mb-1 text-white">
                        <div>የደመወዝ ዋስትና</div>
                        <div className="btn-group" role="group" aria-label="Basic example">
                        
                          <Button
                            onClick={() => {
                              setEditingRecord({
                                empName: sal.empAmName,
                                empAmName: sal.empAmName,
                                grossSalary: sal.grossSalary,
                                loan: loanId,
                                employeeCompany: sal.employeeCompany_id,
                                companyname: sal.companyname,
                                companyage: sal.companyage,
                                mobileNo: sal.mobileNo,
                                empPhoneNum: sal.empPhoneNum,
                                idNum: sal.idNum,
                                letterNum: sal.letterNum,
                                id:sal.id,
                               
                                regionId: sal?.region_id,
                                zoneId: sal?.zone_id,
                                woredaId: sal?.woreda_id,
                              });
                              setShowAddModal(true);
                              console.log("selected sal");
                              console.log(sal);
                            }}
                             className="btn btn-sm btn-warning"
                             disabled={targetLoan?.loanstatus?.id !== 1}
                          >
                            አስተካክል
                          </Button>
                          <button
                            onClick={() => {
                              setTargetRecord(sal)
                              setDeleteModal(true);
                              console.log('object deleted');
                            }}
                            className="btn btn-sm btn-danger"
                            disabled={targetLoan?.loanstatus?.id !== 1}
                          >
                            አጥፋ
                          </button>
                        </div>
                      </div>
                      {sal.salaryfiles?.length > 0 ? (
                        <>
                          <Alert
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between'
                            }}
                            className="mt-2 py-2 items-center"
                          >
                            <div> በደመወዝ ዋስትና የሚሰጥ የብድር ሰነድ ማረጋገጫ ዝርዝር </div>
                            <Button
                              className="btn btn-warning btn-sm"
                              disabled={targetLoan?.loanstatus?.id !== 1}
                              onClick={() => {
                                setTargetRecord(sal);
                                reLoadData();
                              }}
                            >
                              የፋይል ዝርዝር አጥፋ
                            </Button>
                          </Alert>

                          <Table striped bordered hover style={{ margin: 1 }}>
                            <thead>
                              <tr>
                                <th> የፋይል ስም </th>
                                <th> እይ </th>
                                <th> ድርጊት </th>
                                <th> አጥፋ </th>
                              </tr>
                            </thead>
                            <tbody>
                              {sal.salaryfiles.map(upload => {
                                return (
                                  <tr key={upload.id} className={upload.isUploaded ? `` : `table-warning`}>
                                    <td>{upload.amDesc}</td>
                                    <td>
                                      {upload.isUploaded ? (
                                        <>
                                          {upload.fileType === 'application/pdf' ? (
                                            <a
                                              target="_blank"
                                              href={serverIP + upload.fileUrl}
                                              rel="noopener noreferrer"
                                            >
                                              <FaFileAlt />
                                            </a>
                                          ) : (
                                            <FaEye
                                              onClick={() => {
                                                setTargetRecord(upload);
                                                setShow(true);
                                              }}
                                            />
                                          )}
                                        </>
                                      ) : (
                                        <></>
                                      )}
                                    </td>

                                    <td style={{ width: '30%', padding: 0 }}>
                                      {data.groups[0] === 'ROLE_OFFICER' ? (
                                        <>
                                          <InputGroup style={{ paddingTop: 5 }}>
                                            <input
                                              onChange={e => {
                                                setFile(e.target.files[0]);
                                                console.log(e.target.files[0]);
                                              }}
                                              type="file"
                                              className="form-control"
                                            />
                                            <Button disabled={targetLoan?.loanstatus?.id !== 1} variant="outline-primary" onClick={() => handleUpload(upload.id)}>
                                              {!upload.isUploaded ? <> ጫን </> : <>አዘምን</>}
                                            </Button>
                                          </InputGroup>
                                        </>
                                      ) : (
                                        <>{upload.isUploaded ? 'ተሰቅሏል' : ' '}</>
                                      )}
                                    </td>
                                    <td style={{ width: '10%', padding: 0 }}>
                                      {upload.isUploaded && (
                                        <Button
                                          variant="outline-danger"
                                          disabled={targetLoan?.loanstatus?.id !== 1}
                                          onClick={() => {
                                            handleDeleteFile(upload.id);
                                            // setDeleteModal(true);
                                            console.log('object deleted');
                                          }}
                                        >
                                          አጥፋ
                                        </Button>
                                      )}
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </Table>
                        </>
                      ) : (
                        <Alert className="px-3 flex flex-row justify-between py-1 items-center">
                          <div> በደመወዝ ዋስትና የሚሰጥ የብድር ሰነድ ማረጋገጫ ዝርዝር </div>
                          <Button className="btn-sm" onClick={() => loadData(sal.id)}>
                            ሳብ
                          </Button>
                        </Alert>
                      )}
                    </Accordion.Body>
                  </Accordion.Item>
                );
              })}
            </Accordion>
          ) : (
            <>Bring Files</>
          )}
        </div>
      </div>

    </div>
  )
}

export default Salary