import React from 'react'

function NewFormInput({ label, placeh, value, onChange, disabled}) {
    return (
        <div className='flex w-full mb-1'>
            <div className='flex w-1/2 justify-end items-center pr-2'>
                {label}
            </div>
            <div className='flex w-1/2'>
                <input
                    className='form-control'
                    value={value}
                    placeholder={placeh}
                    onChange={onChange}
                    disabled={disabled}
                />
            </div>
        </div>
    )
}

export default NewFormInput