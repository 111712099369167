import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { FaTelegram } from 'react-icons/fa';
import { Button, Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
// redux toolkit
import { setTargetClient, setTargetLoan } from '../Common/redux/systemLookups';
import TabOrganizer from '../Tabs/TabOrganizer';
import ChatBox from '../Common/ChatBox';
import { fifthC, firstC, thirdC } from '../Common/modules/NewFiveCSums';
import { toast } from 'react-toastify';
import axiosInstance from '../axiosInstance';

function LoanOnOfficer() {
  const dispatch = useDispatch();
  const [t] = useTranslation('global');
  const { serverIP } = useSelector(store => store.allsettings);
  const { targetLoan, targetClient } = useSelector(store => store.systemLookups);
  const { data } = useSelector(store => store.customer);
  const [modalToTeamLeader, setModalToTeamLeader] = useState(false);
  const { customerId, loanId } = useParams();
  const [doNotCheck5c, setDoNotCheck5c] = useState(false);

  useEffect(() => {
    getCustomer_Loan();
  }, []);

  const getCustomer_Loan = async () => {
    try {
      await getLoan();
      await getCustomer();
    } catch (error) {
      console.error('Error in redirecting:', error);
    }
  };

  const getLoan = async () => {
    try {
      const res = await axiosInstance.get(`${serverIP}/loan/loans/${loanId}`);
      dispatch(setTargetLoan({ targetLoan: res.data }));
    } catch (err) {
      console.error('Error fetching loan:', err);
    }
  };

  const getCustomer = async () => {
    try {
      const res = await axiosInstance.get(`${serverIP}/customer/customers/${customerId}`);
      dispatch(setTargetClient({ client: res.data }));
    } catch (err) {
      console.error('Error fetching customer:', err);
    }
  };

  const sendToTeamLeader = () => {
    if (
      firstC(targetClient, targetLoan) === 35 &&
      thirdC(targetClient, targetLoan) >= 13 &&
      fifthC(targetLoan) === 25 || doNotCheck5c
    ) {
      axiosInstance
        .patch(`${serverIP}/loan/loans/${targetLoan.id}`, {
          loanstatus: 2
        })
        .then(res => {
          console.log(res.data);
          setModalToTeamLeader(false);
          getLoan();
        })
        .catch(err => {
          console.log(err);
        });
    } else {
      toast.error(' 5C አልተሟላም :: ');
    }
    setModalToTeamLeader(false);
  };

  return (
    <div>
      <div className='flex justify-between bg-slate-200 px-2 items-center'>
        <div className='flex gap-4'>
          <b className='text-orange-400'>{targetClient.amDisplayName}</b>
          <b>{targetClient.displayName}</b>
          <b className='text-orange-400'> {`External #: ${targetClient.entityExternalId}`}</b>
          <b> {`Account #: ${targetClient.entityAccountNo}`}</b>
        </div>
        <div>
          {targetLoan?.loanstatus?.id === 1 && <Button onClick={() => setModalToTeamLeader(true)} className="flex flex-row gap-1 px-2 items-center">
            <FaTelegram /> ወደ ቡድን መሪ
          </Button>}
          {targetLoan?.loanstatus?.id === 2 && <> ወደ ቡድን መሪ ተልኳል </>}
          {targetLoan?.loanstatus?.id === 3 && <> ወደ ኦዲተር ተልኳል </>}
        </div>
      </div>
      
      {/* Modal Edit Start  */}
      <Modal show={modalToTeamLeader} onHide={() => setModalToTeamLeader(false)}>
        <Modal.Header closeButton>
          <Modal.Title style={{ color: 'orange' }}>ወደ ቡድን መሪ</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            ወደ ቡድን መሪ ለመላክ እርግጠኛ ነዎት?
          </p>
          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              onChange={e => {
                setDoNotCheck5c(e.target.checked);
              }}
            />
            <label className="form-check-label"> 5C አሳልፈው </label>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button className="flex flex-row gap-1 px-2"
            onClick={() => {
              sendToTeamLeader();
            }}>
            <FaTelegram size={25} />
            {t('officer.send')}
          </Button>
        </Modal.Footer>
      </Modal>
      {/* Modal Edit End  */}

      <TabOrganizer />

      <ChatBox />
    </div>
  );
}

export default LoanOnOfficer;
