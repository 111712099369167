import React, { useState } from 'react';
import ListGroup from 'react-bootstrap/ListGroup';
import ChatBox from '../Common/ChatBox';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Alert, Button, Modal } from 'react-bootstrap';
import { FaBackward, FaEye, FaFileAlt, FaTelegram } from 'react-icons/fa';
import { ToastContainer, toast } from 'react-toastify';
import axiosInstance from '../axiosInstance';
import { singleCarPrice, singleHomePrice } from '../Common/modules/NewFiveCSums';
import { setTargetLoan } from '../Common/redux/systemLookups';

function LoanDecide() {
    const { serverIP } = useSelector(store => store.allsettings);
    const { targetLoan, targetClient } = useSelector(store => store.systemLookups);
    const dispatch = useDispatch();
    const { loanId } = useParams();
    const { data } = useSelector(store => store.customer);

    const [modalApprove, setModalApprove] = useState(false);
    const [modalToAuditor, setModalToAuditor] = useState(false);
    const [modalSendBack, setModalSendBack] = useState(false);
    const [modalToTeamLeader, setModalToTeamLeader] = useState(false);
    

    const [viewObj, setViewObj] = useState([]);
    const [show, setShow] = useState(false);

    const approveLoan = () => {
        axiosInstance
            .patch(`${serverIP}/loan/loans/${loanId}`, {
                loanstatus: 4
            })
            .then(res => {
                setModalApprove(false);
                toast.success('Sucessfully Approved');
                getLoan();
            })
            .catch(err => {
                console.log(err);
            });
    };

    const toAuditor = () => {
        axiosInstance
            .patch(`${serverIP}/loan/loans/${loanId}`, {
                loanstatus: 3
            })
            .then(res => {
                setModalApprove(false);
                setModalToAuditor(false);
                toast.success('Sucessfully Approved');
                getLoan();
            })
            .catch(err => {
                console.log(err);
            });
    };

    const sendBack = () => {
        console.log('ብድሩን ወደ ኦፊሰር መልስ');
        axiosInstance
            .patch(`${serverIP}/loan/loans/${targetLoan.id}`, {
                loanstatus: 1
            })
            .then(res => {
                console.log(res.data);
                setModalSendBack(false);
                getLoan();
            })
            .catch(err => {
                console.log(err);
            });
    };

    const backToTeamleader = () => {
        console.log('ብድሩን ወደ ኦፊሰር መልስ');
        axiosInstance
            .patch(`${serverIP}/loan/loans/${targetLoan.id}`, {
                loanstatus: 2
            })
            .then(res => {
                console.log(res.data);
                setModalToTeamLeader(false);
                getLoan();
            })
            .catch(err => {
                console.log(err);
            });
    };

    

    const getLoan = async () => {
        try {
            const res = await axiosInstance.get(`${serverIP}/loan/loans/${loanId}`);
            dispatch(setTargetLoan({ targetLoan: res.data }));
        } catch (err) {
            console.error('Error fetching loan:', err);
        }
    };



    return (
        <div>
            <ToastContainer autoClose={1000} />

            <Modal size="lg" show={show} onHide={() => setShow(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>{viewObj.amDesc}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {/* {JSON.stringify(setViewObj)} */}
                    <img src={serverIP + viewObj.fileUrl} style={{ width: '100%', height: '100%' }} />
                </Modal.Body>
            </Modal>

            {/* Modal Send Back Start  */}
            <Modal show={modalSendBack} onHide={() => setModalSendBack(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>ብድሩን ወደ ኦፊሰር መልስ </Modal.Title>
                </Modal.Header>
                <Modal.Body> ብድሩን ወደ ኦፊሰር መልስ </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="warning btn-sm"
                        onClick={() => {
                            sendBack();
                        }}
                    >
                        መልስ
                    </Button>
                </Modal.Footer>
            </Modal>
            {/* Modal Send Back End  */}

             {/* Modal Approve Start  */}
             <Modal show={modalToTeamLeader} onHide={() => setModalToTeamLeader(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>የብድር እቅዱን  ወደ ቡድን መሪ መልስ </Modal.Title>
                </Modal.Header>
                <Modal.Body>የብድር እቅዱን ወደ ቡድን መሪ መልስ </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="primary btn-sm"
                        onClick={() =>  backToTeamleader()}
                    >
                        መልስ 
                    </Button>
                </Modal.Footer>
            </Modal>
            {/* Modal Approve End  */}


            {/* Modal Approve Start  */}
            <Modal show={modalApprove} onHide={() => setModalApprove(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>የብድር እቅዱን አጽድቅ </Modal.Title>
                </Modal.Header>
                <Modal.Body>የብድር እቅዱን አጽድቅ</Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="primary btn-sm"
                        onClick={() => {
                            approveLoan();
                        }}
                    >
                        አጽድቅ
                    </Button>
                </Modal.Footer>
            </Modal>
            {/* Modal Approve End  */}

            {/* Modal Approve Start  */}
            <Modal show={modalToAuditor} onHide={() => setModalToAuditor(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>የብድር እቅዱን ወደ ኦዲተር ላክ </Modal.Title>
                </Modal.Header>
                <Modal.Body>የብድር እቅዱን ወደ ኦዲተር ላክ</Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="primary btn-sm"
                        onClick={() => {
                            toAuditor();
                        }}
                    >
                        ወደ ኦዲተር
                    </Button>
                </Modal.Footer>
            </Modal>
            {/* Modal Approve End  */}

            <div className="px-3 flex flex-row justify-between p-1 items-center bg-gray-500 mb-2 rounded-md">
                <div style={{ color: 'white' }}>
                    <b>{targetClient.amDisplayName}</b> &nbsp;&nbsp;&nbsp;
                    <b style={{ color: 'black', fontStyle: 'italic' }}>{targetClient.displayName}</b>
                </div>

                {data.groups[0] === 'ROLE_TEAM_LEADER' && <div>
                    {targetLoan?.loanstatus?.id === 3 && "ወደ ኦዲተር ተልኳል!"}

                    {targetLoan?.loanstatus?.id === 2 && (
                        <div className="flex gap-1">
                            <Button className="btn btn-warning" onClick={() => setModalSendBack(true)}>
                                ውድቅ አድርግ
                            </Button>
                            <Button onClick={() => setModalToAuditor(true)} className="flex flex-row items-center gap-2">
                                <FaTelegram /> ወደ ኦዲተር ላክ
                            </Button>
                        </div>
                    )}
                    {targetLoan?.loanstatus?.id === 1 && <> ወደ ኦፊሰር ተመልሷል! </>}
                </div>}

                {data.groups[0] === 'ROLE_AUDITOR' && <div>
                    {targetLoan?.loanstatus?.id === 3 && (
                        <div className='flex gap-2'>
                            <Button onClick={() => setModalToTeamLeader(true)} variant='danger' className="flex flex-row items-center gap-2">
                                <FaBackward /> ወደ ቡድን መሪ መልስ 
                            </Button>
                            <Button onClick={() => setModalApprove(true)} className="flex flex-row items-center gap-2">
                                <FaTelegram /> አጽድቅ
                            </Button>
                        </div>
                       
                    )}
                    {targetLoan?.loanstatus?.id === 4 && <> ጸድቋል </>}
                    {targetLoan?.loanstatus?.id === 2 && "ቡድን መሪ ይዞታል!"}
                    {targetLoan?.loanstatus?.id === 1 && <> ወደ ኦፊሰር ተመልሷል! </>}
                </div>}
            </div>

            <div className="row">
                <div className="col-sm-3">
                    <ListGroup>
                        <ListGroup.Item active="true">የደንበኛው መረጃ </ListGroup.Item>
                        <ListGroup.Item>ሙሉ ስም፡ {targetClient.amDisplayName}</ListGroup.Item>
                        <ListGroup.Item>የትዳር ሁኔታ፡ {targetClient.isMarried ? 'ያገባ' : 'ያላገባ'}</ListGroup.Item>
                    </ListGroup>

                    {targetClient.isMarried ? (
                        <>
                            <ListGroup style={{ paddingTop: 15 }}>
                                <ListGroup.Item active="true"> የደንበኛው ፋይሎች </ListGroup.Item>
                                {targetClient.marriedgeneralfiles?.map(mariedf => {
                                    return (
                                        <>
                                            {mariedf.isUploaded ? (
                                                <ListGroup.Item className="flex justify-between">
                                                    <div>{mariedf.amDesc}</div>
                                                    <div>
                                                        {mariedf.fileType === 'application/pdf' ? (
                                                            <a target="_blank" href={serverIP + mariedf.fileUrl} >
                                                                <FaFileAlt />
                                                            </a>
                                                        ) : (
                                                            <FaEye
                                                                onClick={() => {
                                                                    setViewObj(mariedf);
                                                                    setShow(true);
                                                                }}
                                                            />
                                                        )}
                                                    </div>
                                                </ListGroup.Item>) : (<>
                                                    <ListGroup.Item className='bg-orange-200' >
                                                        {mariedf.amDesc}
                                                    </ListGroup.Item>
                                                </>)}
                                        </>
                                    );
                                })}
                            </ListGroup>
                            <ListGroup style={{ paddingTop: 15 }}>
                                <ListGroup.Item active="true">የትዳር አጋር መረጃዎች </ListGroup.Item>
                                <ListGroup.Item>{targetClient.spausedetail[0]?.amFullName}</ListGroup.Item>
                                <ListGroup.Item>{targetClient.spausedetail[0]?.mobileNo}</ListGroup.Item>
                            </ListGroup>
                        </>
                    ) : (
                        <ListGroup style={{ paddingTop: 15 }}>
                            <ListGroup.Item active="true"> የብድር መረጃ ፋይሎች </ListGroup.Item>
                            {targetClient.singlegeneralfiles?.map(single => {
                                return (
                                    <>
                                        {single.isUploaded ? (
                                            <ListGroup.Item className="flex justify-between">
                                                <div>{single.amDesc}</div>
                                                <div>
                                                    {single.fileType === 'application/pdf' ? (
                                                        <a target="_blank" href={serverIP + single.fileUrl} >
                                                            <FaFileAlt />
                                                        </a>
                                                    ) : (
                                                        <FaEye
                                                            onClick={() => {
                                                                setViewObj(single);
                                                                setShow(true);
                                                            }}
                                                        />
                                                    )}
                                                </div>
                                            </ListGroup.Item>
                                        ) : (
                                            <ListGroup.Item className='bg-orange-200'>
                                                {single.amDesc}
                                            </ListGroup.Item>
                                        )}
                                    </>
                                );
                            })}
                        </ListGroup>
                    )}

                    <ListGroup style={{ paddingTop: 15 }}>
                        <ListGroup.Item active="true">የብድር መረጃ </ListGroup.Item>
                        <ListGroup.Item>{`Total Saving: ${targetLoan.totalSaving?.toLocaleString()}`}</ListGroup.Item>
                        <ListGroup.Item>{`Total Share: ${targetLoan.totalShares?.toLocaleString()}`}</ListGroup.Item>
                        <ListGroup.Item>{`Approved Principal: ${targetLoan.r_approvedPrincipal?.toLocaleString()}`}</ListGroup.Item>
                        <ListGroup.Item>{`Total Interest: ${targetLoan.r_totalInterestPayment?.toLocaleString()}`}</ListGroup.Item>
                        <ListGroup.Item>{`Number Of repayments: ${targetLoan.numberOfRepayments}`} Months</ListGroup.Item>
                        <ListGroup.Item>{`Annual interest Rate: ${targetLoan.annualInterestRate}`} %</ListGroup.Item>
                    </ListGroup>
                </div>
                <div className="col-sm-9">
                    <div className="row">
                        <div className="col-sm-4">
                            {/* collateral employee start  */}
                            {targetLoan.collateralemployee?.length > 0 && (
                                <ListGroup>
                                    <ListGroup.Item active="true"> የደመወዝ መያዣዎች </ListGroup.Item>
                                    {targetLoan.collateralemployee.map(emp => {
                                        return (
                                            <>
                                                <ListGroup>
                                                    <ListGroup.Item>{emp.empAmName}</ListGroup.Item>
                                                    <ListGroup.Item>ስልክ ፡ {emp.empPhoneNum}</ListGroup.Item>
                                                    <ListGroup.Item>{`የድርጅት ስም፡ ${emp.companyname}`}</ListGroup.Item>
                                                    <ListGroup.Item>{`የድርጅት ስልክ፡ ${emp.mobileNo}`}</ListGroup.Item>
                                                    <ListGroup.Item>{`የድርጅት እድሜ፡ ${emp.companyage.toLocaleString()}`}</ListGroup.Item>
                                                    <ListGroup.Item>{`ደመወዝ፡ ${emp.grossSalary.toLocaleString()}`}</ListGroup.Item>
                                                </ListGroup>
                                                <ListGroup.Item>
                                                    {emp.salaryfiles.length > 0 && (
                                                        <ListGroup>
                                                            {emp.salaryfiles.map(empf => {
                                                                return (
                                                                    <>
                                                                        {empf.isUploaded ? (
                                                                            <ListGroup.Item className="flex justify-between">
                                                                                <div>{empf.amDesc}</div>
                                                                                <div>
                                                                                    {empf.fileType === 'application/pdf' ? (
                                                                                        <a target="_blank" href={serverIP + empf.fileUrl} rel="noopener noreferrer">
                                                                                            <FaFileAlt />
                                                                                        </a>
                                                                                    ) : (
                                                                                        <FaEye
                                                                                            onClick={() => {
                                                                                                setViewObj(empf);
                                                                                                setShow(true);
                                                                                            }}
                                                                                        />
                                                                                    )}
                                                                                </div>
                                                                            </ListGroup.Item>
                                                                        ) : (
                                                                            <ListGroup.Item className='bg-orange-200'>
                                                                                {empf.amDesc}
                                                                            </ListGroup.Item>
                                                                        )}
                                                                    </>
                                                                );
                                                            })}
                                                        </ListGroup>
                                                    )}
                                                </ListGroup.Item>
                                            </>
                                        );
                                    })}
                                </ListGroup>
                            )}
                            {/* collateral employee end  */}
                            {/* collateral stock start  */}
                            {targetLoan.collateralstock?.length > 0 && (
                                <ListGroup className="pt-3">
                                    <ListGroup.Item active="true"> የአክስዮን መያዣ </ListGroup.Item>
                                    {targetLoan.collateralstock.map(stock => {
                                        return (
                                            <>
                                                <ListGroup>
                                                    <ListGroup.Item>{`የአንድ የአክስዮን ዋጋ፡ ${stock.priceperstock}`}</ListGroup.Item>
                                                    <ListGroup.Item>{`የአክስዮን ብዛት: ${stock.stockqty}`}</ListGroup.Item>
                                                    <ListGroup.Item>{`የአክስዮን የሰጠው ድርጅት: ${stock.bankId?.amName}`}</ListGroup.Item>

                                                    {stock.stockgp !== null ? (
                                                        <ListGroup.Item className="flex justify-between text-green-900 text-2xl">
                                                            <div>{`ባለንብረት: ${stock?.stockgp?.fullname}`}</div>
                                                        </ListGroup.Item>
                                                    ) : (
                                                        <ListGroup.Item className="flex justify-between text-green-900 text-2xl">
                                                            <div>ባለንብረት</div>
                                                            <div>ተበዳሪ</div>
                                                        </ListGroup.Item>
                                                    )}
                                                </ListGroup>
                                                <ListGroup.Item>
                                                    {stock.stockfiles.length > 0 && (
                                                        <ListGroup>
                                                            {stock.stockfiles.map(stf => {
                                                                return (
                                                                    <>
                                                                        {stf.isUploaded ? (
                                                                            <ListGroup.Item className="flex justify-between">
                                                                                <div>{stf.amDesc}</div>
                                                                                <div>
                                                                                    {stf.fileType === 'application/pdf' ? (
                                                                                        <a target="_blank" href={serverIP + stf.fileUrl} rel="noopener noreferrer">
                                                                                            <FaFileAlt />
                                                                                        </a>
                                                                                    ) : (
                                                                                        <FaEye
                                                                                            onClick={() => {
                                                                                                setViewObj(stf);
                                                                                                setShow(true);
                                                                                            }}
                                                                                        />
                                                                                    )}
                                                                                </div>
                                                                            </ListGroup.Item>
                                                                        ) : (
                                                                            <ListGroup.Item className='bg-orange-200'>
                                                                                {stf.amDesc}
                                                                            </ListGroup.Item>
                                                                        )}
                                                                    </>
                                                                );
                                                            })}
                                                        </ListGroup>
                                                    )}
                                                </ListGroup.Item>
                                            </>
                                        );
                                    })}
                                </ListGroup>
                            )}
                            {/* collateral stock end  */}
                        </div>

                        {/* collateral car start  */}
                        {targetLoan.collateralcar?.length > 0 && (
                            <div className="col-sm-4">
                                <ListGroup>
                                    <ListGroup.Item active="true"> የመኪና መያዣዎች </ListGroup.Item>
                                    {targetLoan.collateralcar.map((car, index) => {
                                        return (
                                            <>
                                                <ListGroup.Item>ታርጋ: {car.carPlate} </ListGroup.Item>
                                                <ListGroup.Item>ቻሲስ: {car.chassisNumber} </ListGroup.Item>
                                                <ListGroup.Item>የዋጋ ግምት: {singleCarPrice(car)?.toLocaleString()}</ListGroup.Item>
                                                {car.cargp !== null ? (
                                                    <ListGroup.Item className="flex justify-between text-green-900 text-2xl">
                                                        <div>{`ባለንብረት: ${car?.cargp?.fullname}`}</div>
                                                    </ListGroup.Item>
                                                ) : (
                                                    <ListGroup.Item className="flex justify-between text-green-900 text-2xl">
                                                        <div>ባለንብረት</div>
                                                        <div>ተበዳሪ</div>
                                                    </ListGroup.Item>
                                                )}
                                                <ListGroup.Item index={index}>
                                                    {car.carfiles.length > 0 && (
                                                        <ListGroup>
                                                            {car.carfiles.map(cc => {
                                                                return (
                                                                    <>
                                                                        {cc.isUploaded ? (
                                                                            <ListGroup.Item className="flex justify-between">
                                                                                <div>{cc.amDesc}</div>
                                                                                <div>
                                                                                    {cc.fileType === 'application/pdf' ? (
                                                                                        <a target="_blank" href={serverIP + cc.fileUrl} rel="noopener noreferrer">
                                                                                            <FaFileAlt />
                                                                                        </a>
                                                                                    ) : (
                                                                                        <FaEye
                                                                                            onClick={() => {
                                                                                                setViewObj(cc);
                                                                                                setShow(true);
                                                                                            }}
                                                                                        />
                                                                                    )}
                                                                                </div>
                                                                            </ListGroup.Item>
                                                                        ) : (
                                                                            <ListGroup.Item className='bg-orange-200'>
                                                                                {cc.amDesc}
                                                                            </ListGroup.Item>
                                                                        )}
                                                                    </>
                                                                );
                                                            })}
                                                        </ListGroup>
                                                    )}
                                                </ListGroup.Item>
                                            </>
                                        );
                                    })}
                                </ListGroup>
                            </div>
                        )}

                        {/* collateral car end  */}

                        {/* collateral home start  */}
                        {targetLoan.collateralhome?.length > 0 && (
                            <div className="col-sm-4">
                                <ListGroup>
                                    <ListGroup.Item active="true"> የቤት መያዣ </ListGroup.Item>
                                    {targetLoan.collateralhome.map(home => {
                                        return (
                                            <>
                                                <ListGroup>
                                                    <ListGroup.Item>{home.hometype?.amName}</ListGroup.Item>
                                                    <ListGroup.Item>የካርታ ቁጥር፡ {home.uniquenum}</ListGroup.Item>
                                                    <ListGroup.Item>
                                                        አድራሻ፡ ክ/ከተማ-{home.subcity?.amName}, ወረዳ-
                                                        {home.woreda}, የቤት ቁጥር-{home.houseno}
                                                    </ListGroup.Item>
                                                    <ListGroup.Item>የቦታ ስፋት፡ {home.homearea} ካሬ</ListGroup.Item>

                                                    <ListGroup.Item>
                                                        የዋጋ ግምት፡
                                                        {singleHomePrice(home).toLocaleString()}
                                                    </ListGroup.Item>

                                                    {home.homegp !== null ? (
                                                        <ListGroup.Item className="flex justify-between text-green-900 text-2xl">
                                                            <div>{`ባለንብረት: ${home?.homegp}`}</div>
                                                        </ListGroup.Item>
                                                    ) : (
                                                        <ListGroup.Item className="flex justify-between text-green-900 text-2xl">
                                                            <div>ባለንብረት</div>
                                                            <div>ተበዳሪ</div>
                                                        </ListGroup.Item>
                                                    )}
                                                </ListGroup>
                                                <ListGroup.Item>
                                                    {home.homefiles.length > 0 && (
                                                        <ListGroup>
                                                            {home.homefiles.map(cc => {
                                                                return (
                                                                    <>
                                                                        {cc.isUploaded ? (
                                                                            <ListGroup.Item className="flex justify-between">
                                                                                <div>{cc.amDesc}</div>
                                                                                <div>
                                                                                    {cc.fileType === 'application/pdf' ? (
                                                                                        <a target="_blank" href={serverIP + cc.fileUrl} rel="noopener noreferrer">
                                                                                            <FaFileAlt />
                                                                                        </a>
                                                                                    ) : (
                                                                                        <FaEye
                                                                                            onClick={() => {
                                                                                                setViewObj(cc);
                                                                                                setShow(true);
                                                                                            }}
                                                                                        />
                                                                                    )}
                                                                                </div>
                                                                            </ListGroup.Item>
                                                                        ) : (
                                                                            <ListGroup.Item className='bg-orange-200'>
                                                                                {cc.amDesc}
                                                                            </ListGroup.Item>
                                                                        )}
                                                                    </>
                                                                );
                                                            })}
                                                        </ListGroup>
                                                    )}
                                                </ListGroup.Item>
                                            </>
                                        );
                                    })}
                                    <ListGroup.Item>
                                    </ListGroup.Item>
                                </ListGroup>
                            </div>
                        )}
                        {/* collateral home end  */}
                    </div>
                </div>
            </div>

            <Alert className='mt-2'>
                የዋሶች መረጃ
            </Alert>
            <div className='mb-20 flex w-full gap-2'>
                {targetLoan.gp.length > 0 && targetLoan.gp.map(guarantee => {
                    return <ListGroup className='w-1/4'>
                        <ListGroup.Item variant='active'>
                            {guarantee.fullname}
                        </ListGroup.Item>
                        <ListGroup.Item>
                            {guarantee.gp_files.length > 0 && (
                                <ListGroup>
                                    {guarantee.gp_files.map(cc => {
                                        return (
                                            <>
                                                {cc.isUploaded ? (
                                                    <ListGroup.Item className="flex justify-between">
                                                        <div>{cc.amDesc}</div>
                                                        <div>
                                                            {cc.fileType === 'application/pdf' ? (
                                                                <a target="_blank" href={serverIP + cc.fileUrl} rel="noopener noreferrer">
                                                                    <FaFileAlt />
                                                                </a>
                                                            ) : (
                                                                <FaEye
                                                                    onClick={() => {
                                                                        setViewObj(cc);
                                                                        setShow(true);
                                                                    }}
                                                                />
                                                            )}
                                                        </div>
                                                    </ListGroup.Item>
                                                ) : (
                                                    <ListGroup.Item className='bg-orange-200'>
                                                        {cc.amDesc}
                                                    </ListGroup.Item>
                                                )}
                                            </>
                                        );
                                    })}
                                </ListGroup>
                            )}
                        </ListGroup.Item>
                    </ListGroup>
                })}
            </div>
            <ChatBox />
        </div>
    );
}

export default LoanDecide