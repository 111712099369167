import * as Yup from "yup";

const ShareSchema = Yup.object().shape({
  priceperstock: Yup.number().integer().required("የአንዱ አክስዮን ዋጋ ያስገቡ "),
  stockqty: Yup.number().integer().required("አክስዮን ብዛት ያስገቡ"),
  letternum: Yup.string().required(' የደብዳቤ ቁጥር ያስገቡ'),
  
  bankId: Yup.number().required('ባንክ ይምረጡ'),
  letterdate: Yup.date().required("ደብዳቤው የተሰጠበት ቀን ይምረጡ").max(new Date()),
  serialNumber: Yup.string().required("ሴሪ ቁጥር ያስገቡ "),
});


export default ShareSchema