import React, { useEffect, useState } from 'react'
import axiosInstance from '../axiosInstance';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { FaCarAlt, FaEye, FaFileAlt } from 'react-icons/fa';
import { Accordion, Alert, Badge, Button, Card, InputGroup, Modal, Table } from 'react-bootstrap';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import ShareSchema from '../Schema/ShareSchema';
import InputField from '../ReusableFormiks/InputField';
import { setTargetLoan } from '../Common/redux/systemLookups';
import { PulseLoader } from 'react-spinners';

function Share({ successnotify, errornotify }) {
    const { data } = useSelector(store => store.customer);
    const { serverIP } = useSelector(store => store.allsettings);
    const { targetLoan } = useSelector(store => store.systemLookups);
    const dispatch = useDispatch();
    const { loanId } = useParams();

    const [banks, setBanks] = useState([]);
    const [editingRecord, setEditingRecord] = useState(null);
    const [showAddModal, setShowAddModal] = useState(false);
    const [modalDelete, setModalDelete] = useState(false);

    const [gpModal, setGpModal] = useState(false);
    const [gpid, setGpid] = useState(null);

    const [targetRecord, setTargetRecord] = useState({})
    const [file, setFile] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const [show, setShow] = useState(false);
    const baseStyle = "form-control block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 py-1 px-1 text-base"

    useEffect(() => {
        getBanks();
    }, []);


      if (isLoading) {
        return (
          <div className="h-screen flex items-center justify-center bg-gray-100">
            <div className="flex flex-col gap-3 items-center justify-center">
              <PulseLoader size={30} color="orange" loading="true" />
              <div style={{ color: '#FFA500' }}>Loading ...</div>
            </div>
          </div>
        );
      }

    const getBanks = () => {
        axiosInstance
            .get(`${serverIP}/ddlbanks/ddlbanks`)
            .then(res => {
                console.log(res.data);
                setBanks(res.data);
            })
            .catch(err => {
                console.log(err);
            });
    };

    const initialValues = {
        priceperstock: '',
        stockqty: '',
        letternum: '',

        bankId: '',
        letterdate: '',
        serialNumber: '',
    };

    const handleSubmit = (values, { resetForm }) => {
        console.log('handle submit')
        console.log(values)
        if (editingRecord) {
            // Update existing user
            axiosInstance
                .patch(`${serverIP}/collateral_stock/collateralstock/${targetRecord.id}`, {
                    priceperstock: values.priceperstock,
                    stockqty: values.stockqty,
                    letternum: values.letternum,

                    bankId: values.bankId,
                    letterdate: values.letterdate,
                    serialNumber: values.serialNumber,
                })
                .then(res => {
                    successnotify();
                    setShowAddModal(false);
                    getLoan();
                    console.log(res.data)
                })
                .catch(err => {
                    errornotify();
                    console.log(err)
                });
            getLoan();

            setEditingRecord(false);
            setShowAddModal(false)

        } else {
            axiosInstance
                .post(`${serverIP}/collateral_stock/collateralstock`, {
                    priceperstock: values.priceperstock,
                    stockqty: values.stockqty,
                    letternum: values.letternum,

                    bankId: values.bankId,
                    letterdate: values.letterdate,
                    loan: loanId,
                    serialNumber: values.serialNumber,
                })
                .then(res => {
                    successnotify();
                    setShowAddModal(false);
                    getLoan();
                    console.log(res.data)
                })
                .catch(err => {
                    errornotify();
                    console.log(err)
                });
            getLoan();
            setShowAddModal(false)

        }
        setShowAddModal(false)
        resetForm();
    };

    const getLoan = () => {
        axiosInstance
            .get(`${serverIP}/loan/loans/${targetLoan.id}`)
            .then(res => {
                dispatch(
                    setTargetLoan({
                        targetLoan: res.data
                    })
                );
            })
            .catch(err => {
                console.log(err);
            });
    };


    const deleteRecord = () => {
        axiosInstance
            .delete(`${serverIP}/collateral_stock/collateralstock/${targetRecord.id}`)
            .then(res => {
                setModalDelete(false);
                getLoan();
            })
            .catch(err => {
                console.log(err);
            });
    };

    const loadData = async stockId => {
        setIsLoading(true);
        try {
            const ddlShare = await axiosInstance.get(`${serverIP}/ddlshare/ddlshares`);

            const transformedArray = ddlShare?.data.map(item => ({
                parent: stockId,
                user: data.id,
                amDesc: item.amName,
                checkListId: item.id,
                isMandatory: item.isMandatory,
                markValue: item.markValue
            }));

            const insert_CheckLists = await axiosInstance.post(
                `${serverIP}/collateral_stock_files/stockfiles/bulkinsert`,
                transformedArray
            );
        } catch (error) {
        } finally {
            setIsLoading(false);
            getLoan();
        }
    };


    const reLoadData = async () => {
        try {
            setIsLoading(true);
            await axiosInstance.delete(`${serverIP}/collateral_stock_files/stockfiles/deletebyparent/${targetRecord.id}`);
        } catch (error) {
        } finally {
            setIsLoading(false);
            getLoan();
        }
    };

    const changeGP = () => {
        axiosInstance
            .patch(`${serverIP}/collateral_stock/collateralstock/${targetRecord.id}`, {
                stockgp: gpid
            })
            .then(res => {
                successnotify();
                setGpModal(false);
                getLoan();
            })
            .catch(err => {
                console.log(err);
            });
    };


    function handleUpload(uploaditem) {
        if (!file) {
            errornotify();
            return;
        }

        const fd = new FormData();
        fd.append('file', file);
        fd.append('fileType', file.type);
        fd.append('fileUrl', file);
        fd.append('isUploaded', true);

        axiosInstance
            .patch(`${serverIP}/collateral_stock_files/stockfiles/${uploaditem.id}`, fd, {
                headers: {
                    'Custom-Header': 'value'
                }
            })
            .then(res => {
                getLoan();
                successnotify();
            })
            .catch(err => {
                errornotify();
            });
    }


    const handleDeleteFile = deleteid => {
        axiosInstance
            .patch(`${serverIP}/collateral_stock_files/stockfiles/${deleteid}`, {
                isUploaded: false
            })
            .then(res => {
                successnotify();
                getLoan();
            })
            .catch(err => {
                errornotify();
                console.log(err);
            });
    };


    return (
        <div>
            {/* Modal Show Start  */}
            <Modal size="lg" show={show} onHide={() => setShow(false)}>
                <Modal.Header closeButton>
                    <Modal.Title> {targetRecord.amDesc} </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <img src={serverIP + targetRecord.fileUrl} style={{ width: '100%', height: '100%' }} />
                </Modal.Body>
            </Modal>
            {/* Modal Show End  */}


            {/* Modal Change Guarantee Start */}
            <Modal size="lg" show={gpModal} onHide={() => setGpModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title> ዋስ ግለሰብ ቀይር </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <select
                        className="form-select"
                        onChange={e => {
                            setGpid(e.target.value);
                        }}
                    >
                        <option value="null" selected>
                            ተበዳሪ
                        </option>
                        {targetLoan.gp?.length > 0 &&
                            targetLoan.gp.map(gp => {
                                return (
                                    <option key={gp.id} value={gp.id}>
                                        {gp.fullname}
                                    </option>
                                );
                            })}
                    </select>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="warning btn-sm" onClick={() => changeGP()}>
                        Change
                    </Button>
                </Modal.Footer>
            </Modal>
            {/* Modal Change Guarantee Start  */}

            {/* add modal Start  */}
            <Modal show={showAddModal} onHide={() => {
                setShowAddModal(false)
                setEditingRecord(false)
            }}>
                <Modal.Header closeButton>
                    <Modal.Title> የአክስዮን መያዣ </Modal.Title>
                </Modal.Header>

                <Formik
                    initialValues={editingRecord || initialValues}
                    validationSchema={ShareSchema}
                    onSubmit={handleSubmit}
                    enableReinitialize
                >
                    {({ values, errors, touched, handleChange, setFieldValue }) => (
                        <Form>
                            <Modal.Body >
                                <InputField
                                    label="የአንዱ አክስዮን ዋጋ "
                                    name="priceperstock"
                                    placeholder="የአንዱ አክስዮን ዋጋ"
                                    touched={touched.priceperstock}
                                    errors={errors.priceperstock}
                                />
                                <InputField
                                    label="የአክስዮን ብዛት "
                                    name="stockqty"
                                    placeholder="የአክስዮን ብዛት"
                                    touched={touched.stockqty}
                                    errors={errors.stockqty}
                                />
                                <InputField
                                    label="የደብዳቤ ቁጥር"
                                    name="letternum"
                                    placeholder="የደብዳቤ ቁጥር"
                                    touched={touched.letternum}
                                    errors={errors.letternum}
                                />
                                <InputField
                                    label="ሴሪ ቁጥር"
                                    name="serialNumber"
                                    placeholder="ሴሪ ቁጥር"
                                    touched={touched.serialNumber}
                                    errors={errors.serialNumber}
                                />
                                <label className="block text-sm font-medium text-gray-700 mb-1">
                                    ባንክ
                                </label>
                                <Field
                                    as="select"
                                    name="bankId"
                                    className={baseStyle}
                                >
                                    <option value="">ባንክ ይምረጡ </option>
                                    {banks.map(bank => (
                                        <option key={bank.id} value={bank.id}>
                                            {bank.amName}
                                        </option>
                                    ))}
                                </Field>
                                <ErrorMessage style={{ color: 'red' }} name="bankId" component="div" />


                                <InputField
                                    label="ደብዳቤ የተሰጠበት ቀን"
                                    type='date'
                                    name="letterdate"
                                    placeholder="የደብዳቤ ቁጥር"
                                    touched={touched.letterdate}
                                    errors={errors.letterdate}
                                />

                            </Modal.Body>
                            <Modal.Footer className='flex justify-end'>
                                <Button type="submit" variant={editingRecord ? "warning" : "primary"} >
                                    {editingRecord ? 'Update' : 'Save'}
                                </Button>
                            </Modal.Footer>
                        </Form>
                    )}
                </Formik>

            </Modal>
            {/* add modal end  */}

            {/* delete modal Start  */}
            <Modal show={modalDelete} onHide={() => setModalDelete(false)}>
                <Modal.Header closeButton>
                    <Modal.Title> በመያዣነት የቀረበው አክስዮን አጥፋ </Modal.Title>
                </Modal.Header>
                <Modal.Body>!</Modal.Body>
                <Modal.Footer>
                    <Button variant="danger btn-sm" onClick={() => deleteRecord()}>
                        አጥፋ
                    </Button>
                </Modal.Footer>
            </Modal>
            {/* delete modal End  */}

            {/* modal end  */}

            <Alert className="px-3 flex flex-row justify-between py-1 items-center">
                <div style={{ fontSize: 20, fontWeight: 'bold', color: 'GrayText' }}>የአክስዮን መያዣ</div>
                <div>
                    {data?.groups[0] === 'ROLE_OFFICER' && (
                        <Button
                            onClick={() => setShowAddModal(true)}
                            className="flex flex-row items-center gap-2"
                            disabled={targetLoan?.loanstatus?.id !== 1}
                        >
                            <FaCarAlt /> ጨምር
                        </Button>
                    )}
                </div>
            </Alert>

            {/* accordion end  */}

            <div className="row">
                <div className="col-sm-12" style={{ paddingTop: 5 }}>
                    {Object.keys(targetLoan)?.length > 0 ? (
                        <Accordion defaultActiveKey="1">
                            {targetLoan.collateralstock.map(stock => {
                                return (
                                    <Accordion.Item eventKey={stock.id}>
                                        <Accordion.Header>
                                            <div className="flex flex-row items-center gap-2">
                                                <FaFileAlt />
                                                <Badge>
                                                    {stock.stockfiles.filter(x => x.isUploaded === true)?.length}/ {stock.stockfiles?.length}
                                                </Badge>
                                                {`${stock.bankId.amName} --- ${stock.priceperstock}*${stock.stockqty} = ${(
                                                    stock.priceperstock * stock.stockqty
                                                )?.toLocaleString()}`}
                                            </div>
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            <div className="flex flex-row p-2 justify-between bg-slate-500 mb-1 text-white">
                                                <div>{stock.stockgp == null ? "ተበዳሪ" : stock.stockgp.fullname} </div>
                                                <div className="btn-group" role="group" aria-label="Basic example">
                                                    <Button
                                                        onClick={() => {
                                                            setEditingRecord({
                                                                priceperstock: stock.priceperstock,
                                                                stockqty: stock.stockqty,
                                                                letternum: stock.letternum,

                                                                bankId: stock.bankId.id,
                                                                letterdate: stock.letterdate,
                                                                loan: loanId,

                                                                serialNumber: stock.serialNumber,
                                                            });
                                                            setShowAddModal(true);
                                                            console.log("selected car");
                                                            console.log(stock);
                                                            setTargetRecord(stock)
                                                        }}
                                                        className="btn btn-sm btn-warning"
                                                        disabled={targetLoan?.loanstatus?.id !== 1}
                                                    >
                                                        አስተካክል
                                                    </Button>

                                                    <button
                                                        onClick={() => {
                                                            setGpModal(true);
                                                            setTargetRecord(stock);
                                                        }}
                                                        type="button"
                                                        className="btn btn-sm btn-primary"
                                                        disabled={targetLoan?.loanstatus?.id !== 1}
                                                    >
                                                        ተያዥ ቀይር
                                                    </button>

                                                    <button
                                                        onClick={() => {
                                                            setTargetRecord(stock);
                                                            setModalDelete(true);
                                                        }}
                                                        className="btn btn-sm btn-danger"
                                                        disabled={targetLoan?.loanstatus?.id !== 1}
                                                    >
                                                        አጥፋ
                                                    </button>
                                                </div>
                                            </div>
                                            {stock.stockfiles?.length > 0 ? (
                                                <>
                                                    <Alert
                                                        style={{
                                                            display: 'flex',
                                                            justifyContent: 'space-between'
                                                        }}
                                                        className="mt-2 py-2 items-center"
                                                    >
                                                        <div>በአክሲዮን ሰርተፍኬት ዋስትና የሚሰጥ የብድር ሰነድ ማረጋገጫ ዝርዝር</div>
                                                        <Button
                                                            className="btn btn-warning btn-sm"
                                                            disabled={targetLoan?.loanstatus?.id !== 1}
                                                            onClick={() => {
                                                                setTargetRecord(stock);
                                                                reLoadData();
                                                            }}
                                                        >
                                                            የፋይል ዝርዝር አጥፋ
                                                        </Button>
                                                    </Alert>

                                                    <Table striped bordered hover style={{ margin: 1 }}>
                                                        <thead>
                                                            <tr>
                                                                <th> የፋይል ስም </th>
                                                                <th> እይ </th>
                                                                <th> ድርጊት </th>
                                                                <th> አጥፋ </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {stock.stockfiles.map(upload => {
                                                                return (
                                                                    <tr key={upload.id} className={upload.isUploaded ? `` : `table-warning`}>
                                                                        <td>{upload.amDesc}</td>
                                                                        <td>
                                                                            {upload.isUploaded ? (
                                                                                <>
                                                                                    {upload.fileType === 'application/pdf' ? (
                                                                                        <a
                                                                                            target="_blank"
                                                                                            href={serverIP + upload.fileUrl}
                                                                                            rel="noopener noreferrer"
                                                                                        >
                                                                                            <FaFileAlt />
                                                                                        </a>
                                                                                    ) : (
                                                                                        <FaEye
                                                                                            onClick={() => {
                                                                                                setTargetRecord(upload);
                                                                                                setShow(true);
                                                                                                console.log("clicked")
                                                                                            }}
                                                                                        />
                                                                                    )}
                                                                                </>
                                                                            ) : (
                                                                                <></>
                                                                            )}
                                                                        </td>

                                                                        <td style={{ width: '30%', padding: 0 }}>
                                                                            {data.groups[0] === 'ROLE_OFFICER' ? (
                                                                                <>
                                                                                    <InputGroup style={{ paddingTop: 5 }}>
                                                                                        <input
                                                                                            onChange={e => {
                                                                                                setFile(e.target.files[0]);
                                                                                                console.log(e.target.files[0]);
                                                                                            }}
                                                                                            type="file"
                                                                                            className="form-control"
                                                                                        />
                                                                                        <Button variant="outline-primary" onClick={() => handleUpload(upload)}>
                                                                                            {!upload.isUploaded ? <> ጫን </> : <>አዘምን</>}
                                                                                        </Button>
                                                                                    </InputGroup>
                                                                                </>
                                                                            ) : (
                                                                                <>{upload.isUploaded ? 'ተሰቅሏል' : ' '}</>
                                                                            )}
                                                                        </td>
                                                                        <td style={{ width: '10%', padding: 0 }}>
                                                                            {upload.isUploaded && (
                                                                                <Button variant="outline-danger" onClick={() => handleDeleteFile(upload.id)}>
                                                                                    አጥፋ
                                                                                </Button>
                                                                            )}
                                                                        </td>
                                                                    </tr>
                                                                );
                                                            })}
                                                        </tbody>
                                                    </Table>
                                                </>
                                            ) : (
                                                <Alert className="px-3 flex flex-row justify-between py-1 items-center">
                                                    <div>በአክሲዮን ሰርተፍኬት ዋስትና የሚሰጥ የብድር ሰነድ ማረጋገጫ ዝርዝር</div>
                                                    <Button className="btn-sm" onClick={() => loadData(stock.id)}>
                                                        ሳብ
                                                    </Button>
                                                </Alert>
                                            )}
                                        </Accordion.Body>
                                    </Accordion.Item>
                                );
                            })}
                        </Accordion>
                    ) : (
                        <></>
                    )}
                </div>
            </div>


        </div>
    )
}


export default Share