import React from 'react'
import { Badge } from 'react-bootstrap'

function LoanStatus({ loan }) {
    return (

        <Badge>
            {loan?.loanstatus?.id === 1 && loan.officerid?.first_name}
            {loan?.loanstatus?.id === 2 && loan.auditorid?.first_name}
            {loan?.loanstatus?.id === 3 && loan.auditorid?.first_name}
            {loan?.loanstatus?.id === 4 && loan.auditorid?.first_name}
        </Badge>

    )
}

export default LoanStatus