import axiosInstance from '../../axiosInstance';
import React, { useEffect, useState } from 'react';
import { Alert, Button, ButtonGroup, Table, Modal } from 'react-bootstrap';
import { FaPencilAlt, FaTrashAlt } from 'react-icons/fa';

import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

function Homeuse() {
  const [t, i18n] = useTranslation('global');
  const { serverIP } = useSelector(store => store.allsettings);
  const [name, setName] = useState('');
  const [amName, setAmName] = useState('');
  const [percentAllowed, setPercentAllowed] = useState('');

  const [lstBanks, setLstBanks] = useState([]);
  const [modalAdd, setModalAdd] = useState(false);
  const [modalEdit, setModalEdit] = useState(false);
  const [modalDelete, setModalDelete] = useState(false);
  const [targetObj, setTargetObj] = useState({});

  useEffect(() => {
    getBankList();
  }, []);

  const getBankList = () => {
    axiosInstance
      .get(`${serverIP}/homeuse/homeuse`)
      .then(function (response) {
        setLstBanks(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const saveCheckListType = () => {
    axiosInstance
      .post(`${serverIP}/homeuse/homeuse`, {
        enName: name,
        amName: amName,
        percentAllowed: percentAllowed
      })
      .then(res => {
        console.log(res.data);
        setModalAdd(false);
        getBankList();
      })
      .catch(err => {
        console.log(err);
      });
  };

  const editCheckListType = () => {
    console.log('editCheckListType');
    axiosInstance
      .patch(`${serverIP}/homeuse/homeuse/${targetObj.id}`, {
        enName: name,
        amName: amName,
        percentAllowed: percentAllowed
      })
      .then(res => {
        console.log(res.data);
        getBankList();
        setModalEdit(false);
      })
      .catch(err => {
        console.log(err);
      });
  };

  const deleteBank = () => {
    axiosInstance
      .delete(`${serverIP}/homeuse/homeuse/${targetObj.id}`)
      .then(res => {
        console.log(res.data);
        getBankList();
        setModalDelete(false);
      })
      .catch(err => {
        console.log(err);
      });
  };

  return (
    <div>
      {/* Modals Start  */}
      {/* Modal ADD Start  */}
      <Modal show={modalAdd} onHide={() => setModalAdd(false)}>
        <Modal.Header closeButton>
          <Modal.Title>
            <div>
              <div> አዲስ </div>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <InputGroup className="mb-3">
            <InputGroup.Text id="basic-addon1">ስም </InputGroup.Text>
            <Form.Control placeholder="ስም" aria-describedby="basic-addon1" onChange={e => setAmName(e.target.value)} />
          </InputGroup>
          <InputGroup className="mb-3">
            <InputGroup.Text id="basic-addon1">Name </InputGroup.Text>
            <Form.Control placeholder="name" aria-describedby="basic-addon1" onChange={e => setName(e.target.value)} />
          </InputGroup>
          <InputGroup className="mb-3">
            <InputGroup.Text id="basic-addon1"> % </InputGroup.Text>
            <Form.Control max="1" min="0" type='number' placeholder="%" aria-describedby="basic-addon1" onChange={e => setPercentAllowed(e.target.value)} />
          </InputGroup>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={() => saveCheckListType()}>
            {t('officer.save')}
          </Button>
        </Modal.Footer>
      </Modal>
      {/* Modal Add End  */}

      {/* Modal Edit Start  */}
      <Modal show={modalEdit} onHide={() => setModalEdit(false)}>
        <Modal.Header closeButton>
          <Modal.Title style={{ color: 'orange' }}> Edit </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <InputGroup className="mb-3">
            <InputGroup.Text id="basic-addon1">ስም </InputGroup.Text>
            <Form.Control
              placeholder="ስም"
              aria-describedby="basic-addon1"
              value={amName}
              onChange={e => setAmName(e.target.value)}
            />
          </InputGroup>
          <InputGroup className="mb-3">
            <InputGroup.Text id="basic-addon1">Name </InputGroup.Text>
            <Form.Control
              placeholder="name"
              aria-describedby="basic-addon1"
              value={name}
              onChange={e => setName(e.target.value)}
            />
          </InputGroup>
          <InputGroup className="mb-3">
            <InputGroup.Text id="basic-addon1">% </InputGroup.Text>
            <Form.Control
              placeholder="name"
              aria-describedby="basic-addon1"
              value={percentAllowed}
              type='number'
              onChange={e => setPercentAllowed(e.target.value)}
            />
          </InputGroup>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="warning" onClick={() => editCheckListType()}>
            {t('officer.edit')}
          </Button>
        </Modal.Footer>
      </Modal>
      {/* Modal Edit End  */}

      {/* Modal Edit Start  */}
      <Modal show={modalDelete} onHide={() => setModalDelete(false)}>
        <Modal.Header closeButton>
          <Modal.Title style={{ color: 'red' }}> ሰርዝ </Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure</Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={() => deleteBank()}>
            {t('officer.delete')}
          </Button>
        </Modal.Footer>
      </Modal>
      {/* Modal Edit End  */}

      {/* Modals End  */}

      <Alert className="px-3 flex flex-row justify-between py-1 items-center">
        <div> Home Use (Lookup) </div>
        <div>
          <Button variant="primary" className="btn-sm" onClick={() => setModalAdd(true)}>
            {t('officer.add')}
          </Button>
        </div>
      </Alert>
      {lstBanks.length > 0 && (
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>#</th>
              <th> ስም </th>
              <th> % </th>
              <th>{t('auditor.action')}</th>
            </tr>
          </thead>
          <tbody>
            {lstBanks.map(chk => {
              return (
                <tr>
                  <td>{chk.id}</td>
                  <td>{chk.amName}</td>
                  <td>{`${chk.percentAllowed} or ${chk.percentAllowed * 100}%`}</td>
                  <td>
                    <ButtonGroup size="sm">
                      <FaTrashAlt
                        color="red"
                        onClick={() => {
                          setModalDelete(true);
                          setTargetObj(chk);
                        }}
                      />
                      &nbsp;
                      <FaPencilAlt
                        color="orange"
                        onClick={() => {
                          setModalEdit(true);
                          setTargetObj(chk);
                          setName(chk.enName);
                          setAmName(chk.amName);
                          setPercentAllowed(chk.percentAllowed);
                        }}
                      />
                    </ButtonGroup>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      )}
    </div>
  );
}

export default Homeuse