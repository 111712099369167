import * as Yup from "yup";

const BailSchema = Yup.object().shape({
  fullname: Yup.string()
    .min(6, "ሙሉ ስም ክ6 ፊደል ማነስ የለበትም")
    .required("ሙሉ ስም ይፈለጋል"),

  regionId: Yup.number().required("ክልል ይምረጡ"),
  zoneId: Yup.number().required("ዞን ይምረጡ"),
  woredaId: Yup.number().required("ወረዳ ይምረጡ"),

  housenum: Yup.string().required("የቤት ቁጥር ይፈለጋል"),
  idnum: Yup.string().required("የመታወቅያ ቁጥር ይፈለጋል"),
  phonenum: Yup.string()
    .min(9, "ከ 9 ቁጥሮች ማነስ የለበትም")
    .max(9, "ከ 9 ባላይ አይቻልም")
    .required("ስልክ ቁጥር ይፈለጋል"),
  isMarried: Yup.boolean(),
  isCollateralgp: Yup.boolean(),
});

export default BailSchema;