import * as Yup from "yup";

const HouseSchema = Yup.object().shape({
  buildingmaterial: Yup.number().required("ቤቱ የተሰራበት Material ይምረጡ"),
  buildingtype: Yup.number().required("የህንጻው አይነት ይምረጡ"),
  floorlocation: Yup.number().required("ቤቱ ያረፈበት ወለል ይምረጡ"),

  fromasphalt: Yup.number().required("ቤት ከአስፋልት ያለውን ርቀት ይምረጡ"),
  location: Yup.number().required("ቤቱ የሚገኝበት ከተማ ይምረጡ"),
  neighbour: Yup.number().required("የቤቱ አዋሳኝ ይምረጡ"),

  physicalappearance: Yup.number().required("ቤቱ ሲታይ ያለበት ሁኔታ ይምረጡ"),
  setback: Yup.number().required("ቤቱ ከመንገድ ያለው ርቀት ትክክላኛነት ደረጃ ይምረጡ"),
  homeuse: Yup.number().required("ቤቱ የሚሰጠው አገልግሎት አይነት ይምረጡ"),

  uniquenum: Yup.string().required("የካርታ መ.ቁጥር ያስገቡ"),
  homearea: Yup.number().required("የቤቱ ስፋት ያስገቡ"),
  totalhomearea: Yup.number().required("የቤቱ ስፋት ያስገቡ"),

  serialnum: Yup.string().required("ሴሪ ቁጥር ያስገቡ "),
  sanctionAddress: Yup.string().required("የእግድ አድራሻ "),
  houseno: Yup.string().required("የቤት ቁጥር ያስገቡ"),

  amDescription: Yup.string().required("ቤቱ በተመለከተ ገለጻ "),
  isFenceIsolation: Yup.boolean(),
  accessroadtosite: Yup.number().required("ወደ ቤቱ የሚወስደው መንገድ የተሰራበት "),

  regionId: Yup.number().required("ቤቱ የሚገኝበት ክልል ይምረጡ"),
  // loan: Yup.number().required("ብድር አልተገኘም "),
});

export default HouseSchema;
