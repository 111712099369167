import * as Yup from "yup";

const SpauseSchema = Yup.object().shape({
  amFullName: Yup.string()
    .min(6, "ሙሉ ስም ከ6 ፊደል በታች መሆን የለበትም")
    .required("ሙሉ ስም ያስፈልጋል"),
  regionId: Yup.number().required("ክልል ይምረጡ"),
  zoneId: Yup.number().required("ዞን ይምረጡ"),
  woredaId: Yup.number().required("ወረዳ ይምረጡ"),
  houseNum: Yup.string().required("የቤት ቁጥር ያስፈልጋል"),
  gender: Yup.string().required("እባክዎ ፆታ ይምረጡ"),
  mobileNo: Yup.string()
    .min(9, "Phone number must be exactly 9 characters")
    .max(9, "exceeds 9 characters")
    .required("ስልክ ቁጥር ያስፈልጋል"),
  idNum: Yup.string().required("የመታወቅያ ቁጥር ያስገቡ"),
  monthlyIncome: Yup.number().required("ወርሃዊ ገቢ ያስገቡ"),
});

export default SpauseSchema;
