import * as Yup from "yup";

const SalarySchema = Yup.object().shape({
  empAmName: Yup.string().required("የዋስ ሙሉ ስም ያስፈልጋል "),
  grossSalary: Yup.string().required("ሙሉ ደመወዝ / ከታክስ በፊት ያስገቡ"),
  employeeCompany: Yup.string().required("የድርጅት አይነት ይምረጡ"),

  companyname: Yup.string().required("የድርጅት ስም ያስገቡ"),
  companyage: Yup.number().required("የድርጅት እድሜ ያስገቡ"),
  mobileNo: Yup.string()
    .min(9, "ስልክ ቁጥር 9 ቁጥሮች መሆን አለባቸው")
    .max(9, "ከ 9 በላይ መሆን አይችልም")
    .required("ስልክ ቁጥር ያስገቡ"),
    
  empPhoneNum: Yup.string()
    .min(9, "ስልክ ቁጥር 9 ቁጥሮች መሆን አለባቸው")
    .max(9, "ከ 9 በላይ መሆን አይችልም")
    .required("ስልክ ቁጥር ያስገቡ"),
  idNum: Yup.string().required("የመታውቅያ ቁጥር ያስገቡ"),
  letterNum: Yup.string().required("የደብዳቤ ቁጥር ያስገቡ"),

  regionId: Yup.number().required("ክልል ይምረጡ"),
  zoneId: Yup.number().required("ዞን ይምረጡ"),
  woredaId: Yup.number().required("ወረዳ ይምረጡ"),

  // subcity: Yup.string().required("ክፍለ ከተማ ይምረጡ"),
  // woreda: Yup.string().required("ወረዳ ይምረጡ"),
});

export default SalarySchema;
