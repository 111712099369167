import React, { useState, useEffect } from 'react';
import { Accordion, Alert } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import axiosInstance from '../../axiosInstance';
import { PulseLoader } from 'react-spinners';
import AmCarProhibition from './AmCarProhibition';
import AmShareProhibition from './AmShareProhibition';
import AmHomeProhibition from './AmHomeProhibition';

function AmProhibitions() {
  const { serverIP } = useSelector(store => store.allsettings);
  const { customerId, loanId } = useParams();
  const [customer, setCustomer] = useState([]);
  const [loan, setLoan] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  if (isLoading) {
    return (
      <div className="h-fit flex items-center justify-center bg-gray-100">
        <div className="flex flex-col gap-3 items-center justify-center">
          <PulseLoader size={30} color="orange" loading="true" />
          <div style={{ color: '#FFA500' }}>Loading ...</div>
        </div>
      </div>
    );
  }


  // useEffect(() => {
  //   getLoan_Customer();
  // }, []);


  // const getLoan_Customer = async () => {
  //   try {
  //     setIsLoading(true)
  //     const cus_res = await axiosInstance.get(`${serverIP}/customer/customers/${customerId}`)
  //     setCustomer(cus_res.data);

  //     const ln_res = axiosInstance.get(`${serverIP}/loan/loans/${loanId}`)
  //     setLoan(ln_res.data);
  //   } catch (error) {

  //   } finally {
  //     setIsLoading(false)
  //   }
  // };

  useEffect(() => {
    getLoan();
    getCustomer();
  }, []);

  const getCustomer = async () => {
    await axiosInstance
      .get(`${serverIP}/customer/customers/${customerId}`)
      .then(res => {
        console.log('customer');
        console.log(res.data);
        setCustomer(res.data);
      })
      .catch(err => {
        console.log(err);
      });
  };

  const getLoan = async () => {
    await axiosInstance
      .get(`${serverIP}/loan/loans/${loanId}`)
      .then(res => {
        console.log('LOAN');
        console.log(res.data);
        setLoan(res.data);
      })
      .catch(err => {
        console.log(err);
      });
  };


  return (
  
    <div>
    <div className="row">
      <div className="col-sm-12">
        <Alert style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div>
            <b>{customer.amDisplayName}</b>
            {' - ' + customer.mobileNo}
          </div>
          <div>
            <b>{customer.gender}</b>
            <b>{customer.isMarried ? ' - Married - ' : ' - Single - '}</b>
            <b> {customer.entityExternalId} </b>
          </div>
        </Alert>
      </div>
    </div>
    <div className="row">
      <div className="col-sm-12">
        <Accordion defaultActiveKey="1">
          {loan?.collateralcar?.length > 0 &&
            loan.collateralcar.map(car => {
              return (
                <>
                  <Accordion.Item eventKey="carp">
                    <Accordion.Header>
                      <b style={{ color: 'red' }}> መኪና እግድ </b> &nbsp;
                      <i>{car.cargp?.fullname}</i>
                    </Accordion.Header>
                    <Accordion.Body>
                      <AmCarProhibition loan={loan} car={car} />
                    </Accordion.Body>
                  </Accordion.Item>
                </>
              );
            })}

          {loan?.collateralhome?.length > 0 &&
            loan.collateralhome.map(home => {
              return (
                <>
                  <Accordion.Item eventKey="homep">
                    <Accordion.Header>
                      <b style={{ color: 'red' }}> የቤት እግድ </b> &nbsp;
                      <i>{home.homegp}</i>
                    </Accordion.Header>
                    <Accordion.Body>
                      <AmHomeProhibition loan={loan} home={home} />
                    </Accordion.Body>
                  </Accordion.Item>
                </>
              );
            })}

          {loan?.collateralstock?.length > 0 &&
            loan.collateralstock.map(stock => {
              return (
                <>
                  <Accordion.Item eventKey="stockp">
                    <Accordion.Header>
                      <b style={{ color: 'red' }}> የአክስዮን እግድ </b> &nbsp;
                      <i>{stock.stockgp?.fullname}</i>
                    </Accordion.Header>
                    <Accordion.Body>
                      <AmShareProhibition loan={loan} stock={stock} />
                    </Accordion.Body>
                  </Accordion.Item>
                </>
              );
            })}
        </Accordion>
      </div>
    </div>
  </div>

  )
}

export default AmProhibitions