import React from "react";
import { Text, Font, View, Image, StyleSheet } from "@react-pdf/renderer";
import AmharicFont from "../../font/ebrima.ttf";
import getEthiopicDate from '../../Common/modules/GregorianToEthiopian';

const styles = StyleSheet.create({
    container: {
        marginTop: -15,
        display: "flex",
        flexDirection: "col",
        justifyContent: "space-between",
        paddingBottom: 5,
    },
    textRight: {
        marginTop: 1,
        fontSize: 12,
        textAlign: 'right',
        fontFamily: 'AmharicFont',
        fontWeight: 'bold'
    },
    subject: {
        fontSize: 13,
        textAlign: 'left',
        fontFamily: 'ebrimabold'
    },
});

Font.register({
    family: "AmharicFont",
    src: AmharicFont,
});

function HomeDateAndAddress({ region, objec }) {
    const curdate = new Date();

    return (
        <View style={styles.container}>
            {region == 4 && <>
                <View style={{ paddingTop: 10 }}>
                    <Text style={styles.textRight}>
                        ቀን ፡ &nbsp;
                        {getEthiopicDate(curdate.getDay(), +' ' + curdate.getMonth() + 1, +' ' + curdate.getFullYear())}
                    </Text>
                    <Text style={styles.textRight}>
                        ቁጥር ፡ አ/ቀ/ብ/OP/___________/2017
                    </Text>
                </View>
                <View style={{ paddingTop: 10 }}>
                    <Text style={styles.subject}>ለሸገር ከተማ አስተዳደር </Text>
                    <Text style={styles.subject}>የ{objec.woredaId?.amDescription} ክ/ከተማ ካዳስተር ጽ/ቤት  </Text>
                    <Text style={styles.subject}>ኦሮምያ  </Text>
                </View>
            </>}


            {region == 1 && <>
                <View style={{ paddingTop: 10 }}>
                    <Text style={styles.textRight}>
                        ቀን ፡ &nbsp;
                        {getEthiopicDate(curdate.getDay(), +' ' + curdate.getMonth() + 1, +' ' + curdate.getFullYear())}
                    </Text>
                    <Text style={styles.textRight}>
                        ቁጥር ፡ አ/ቀ/ብ/OP/___________/2017
                    </Text>
                </View>
                <View style={{ paddingTop: 10 }}>
                    <Text style={styles.subject}>በትግራይ ብሔራዊ ክልላዊ መንግስት</Text>
                    <Text style={styles.subject}>{objec.regionId}</Text>
                </View>
            </>}

            {region == 14 && <>
                <View style={{ paddingTop: 10 }}>
                    <Text style={styles.textRight}>
                        ቀን ፡ &nbsp;
                        {getEthiopicDate(curdate.getDay(), +' ' + curdate.getMonth() + 1, +' ' + curdate.getFullYear())}
                    </Text>
                    <Text style={styles.textRight}>
                        ቁጥር ፡ አ/ቀ/ብ/OP/___________/2017
                    </Text>
                </View>
                <View style={{ paddingTop: 10 }}>
                    <Text style={styles.subject}>በ በአዲስ አበባ አስተዳደር  </Text>
                    <Text style={styles.subject}>{objec.regionId}</Text>

                </View>
            </>}

        </View>
    );
}

export default HomeDateAndAddress