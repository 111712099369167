import React from 'react';
import { Document, Font, Page, Text, StyleSheet, View, Image } from '@react-pdf/renderer';
import { PDFViewer } from '@react-pdf/renderer';
import ReportStyle from '../ReportStyle';
import AmigosLogo from "../../Images/new.PNG";
import Trc from "../Reusables/Trc"
import TblLoanRequest from "../Reusables/TblLoanRequest"
import getEthiopicDate from '../../Common/modules/GregorianToEthiopian';

import TextBoldUnderlined from '../Reusables/TextBoldUnderlined';
import { numberToWords, numberToWordsPercent } from '../../Common/modules/EnNumToWord';
import TextBold from '../Reusables/TextBold';

function AmLoanRequest({ customer, loan }) {
  return (
    <PDFViewer style={{ width: '100%', height: 700 }}>
      <Document title="የቤት መያዣ">
        <Page style={ReportStyle.body} wrap>
          {/* <GuaranteeHeader type="የቤት" /> */}
          <View style={ReportStyle.container}>
            <Image style={ReportStyle.ReporIimage} src={AmigosLogo} />
            <View>
              <View
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center'
                }}
              >
                <Text style={ReportStyle.name}> አሚጎስ የገንዘብ ቁጠባና ብድር ተቋም  </Text>
                <Text style={ReportStyle.name}> የብድር መጠየቅያ ቅጽ </Text>
              </View>
            </View>
            <Image style={ReportStyle.ReporIimage} src={AmigosLogo} />
          </View>
          {/* <View style={{ marginTop: 5}}></View> */}
          <TblLoanRequest zkey="ሙሉ ስም" zvalue={customer.amDisplayName} />
          <TblLoanRequest zkey="አድራሻ/ስልክ/" zvalue={customer.mobileNo} />
          <TblLoanRequest zkey="አባል የሆኑበት ቀን (DD/MM/YY)" zvalue={customer.memberSince} />
          <TblLoanRequest zkey="ጥያቄው የቀረበበት ቀን (DD/MM/YY)" zvalue={loan.submittedOnDate} />
          <TblLoanRequest zkey="መለያ ቁጥር" zvalue={loan.loanId} />
          <TblLoanRequest zkey="የተበዳሪ ዕድሜ" zvalue={customer.dateOfBirth} />
          <TblLoanRequest zkey="ያላቸው የመደበኛ ቁጠባ መጠን" zvalue={loan.amount_compulsary} />
          <TblLoanRequest zkey="ያላቸው የብድር ቁጠባ መጠን" zvalue={loan.amount_compulsary} />
          <TblLoanRequest zkey="ያላቸው የአክስዮን መጠን" zvalue={(loan.r_amount_share)?.toLocaleString()} />
          <TblLoanRequest zkey="የብድር ጥያቄው የቁጠባ ሲሌት በ%" zvalue={loan.amount_compulsary} />
          <TblLoanRequest zkey="የብድር መጠን" zvalue={loan.amount_compulsary} />
          <TblLoanRequest zkey="የብድር መመለሻ ግዜ(ወር)" zvalue={customer.amDisplayName} />
          <TblLoanRequest zkey="የብድር ወለድ" zvalue={customer.amDisplayName} />
          <TblLoanRequest zkey="የአግልግሎት ክፍያ በ%" zvalue={customer.amDisplayName} />
          <TblLoanRequest zkey="ብድር ፕሮሰስ የሚጀምሩበት ቀን" zvalue={customer.amDisplayName} />
          <TblLoanRequest zkey="በመ.ቁጠባ ብድር ይጨመራል" zvalue={customer.amDisplayName} />
          <View style={{
            display: "flex",
            flexDirection: "row",
            borderBottomWidth: 1,
            borderBottomColor: "#FF7700",
            borderBottomStyle: "solid",
            justifyContent: "space-between",
          }}></View>
          <TblLoanRequest zkey="የሂወት መድህን ክፍያ %" zvalue={customer.amDisplayName} />
          <TblLoanRequest zkey="የአግልግሎት እና ኢንሹራንስ ክፍያ" zvalue={customer.amDisplayName} />
          <TblLoanRequest zkey="ለብድር መቀነሻ የሚሆን ቁጠባ" zvalue={customer.amDisplayName} />
          <TblLoanRequest zkey="ዋናው ብድር(principal)" zvalue={customer.amDisplayName} />
          <TblLoanRequest zkey="ተበዳሪ እጅላይ የሚደርስ ብር" zvalue={customer.amDisplayName} />
          <TblLoanRequest zkey="ወርሃዊ ክፍያ" zvalue={customer.amDisplayName} />
          <TblLoanRequest zkey="ጠቅላላ የወለድ መጠን" zvalue={customer.amDisplayName} />
          <TblLoanRequest zkey="መቅረብ ያለበት የዋስትና መጠን" zvalue={customer.amDisplayName} />

        </Page>
      </Document>
    </PDFViewer>
  )
}

export default AmLoanRequest