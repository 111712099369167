import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { setTargetClient, setTargetLoan } from "../Common/redux/systemLookups";
import { FaFile } from "react-icons/fa";
import { PulseLoader } from "react-spinners";
import axiosInstance from '../axiosInstance'
import DataTable from 'react-data-table-component';
import { Button, Modal } from "react-bootstrap";
// import LoanStatus from "../Common/LoanStatus";

function MyLoans() {
  const { serverIP } = useSelector((store) => store.allsettings);
  const { data } = useSelector((store) => store.customer);
  const [t] = useTranslation("global");
  const [loans, setLoans] = useState([]);
  const [filteredLoans, setFilteredLoans] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showTip, setShowTip] = useState(false);

  const columns = [
    {
      name: "ExternalID",
      selector: row => row.customer?.entityExternalId,
      sortable: true
    },
    {
      name: "ሙሉ ስም",
      selector: row => row.customer?.amDisplayName,
      sortable: true
    },
    {
      name: "የተፈቀደው የብድር መጠን",
      selector: row => row.r_approvedPrincipal?.toLocaleString(),
      sortable: true
    },
    {
      name: "ግምገማ",
      selector: row => isValidReport(row.loan_fonance_items) ? (
        <Link
          to={`/loanevaluation/${row?.customer?.id}/${row?.id}`}
          className="font-medium text-blue-400 dark:text-blue-200 underline hover:underline text-xl"
        >
          ግምገማ
        </Link>
      ) : <Button className="btn btn-sm" variant="danger" onClick={() => setShowTip(true)}>
        እንዴት እናስተካክል
      </Button>
    }, {
      name: "ማስተካከያ",
      selector: row => row.loanstatus?.id !== 1 ? <>
      {row.loanstatus?.id === 2 ? "ቡድን መሪ " : " ኦዲተር "}
      </> : (
        <Link
          className="font-medium text-blue-600 dark:text-blue-500 underline hover:underline text-xl"
          disabled={true}
          onClick={() =>
            redirectPage(row?.customer?.id, row?.id)
          }
        >
          ማስታካከያ
        </Link>
      )
    },
    // {
    //   name: "ሁኔታ",
    //   cell: row => <LoanStatus loan={row} />
    // },
    // {
    //   name: "መጠየቅያ ፎርም",
    //   cell: row => <div className="flex items-center gap-1">
    //     <FaFile />
    //     <Link
    //       className="font-medium text-blue-600 dark:text-blue-500 underline hover:underline text-xl"
    //       to={`/amrequestformdoc/${row?.customer?.id}/${row?.id}`}
    //     >
    //       {t("common.request")}
    //     </Link>
    //   </div>
    // },
    {
      name: "ውሎች",
      cell: row => <Link
        to={`/amcontracts/${row?.customer?.id}/${row?.id}`}
        className="font-medium text-blue-400 dark:text-blue-200 underline hover:underline text-xl"
      >
        ውሎች
      </Link>
    },
    {
      name: "እግዶች",
      cell: row => <Link
        to={`/amprohibitions/${row?.customer?.id}/${row?.id}`}
        className="font-medium text-blue-400 dark:text-blue-200 underline hover:underline text-xl"
      >
        እግዶች
      </Link>
    }
  ]

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    getlpscustomers();
  }, []);

  const getlpscustomers = async () => {
    try {
      setIsLoading(true);

      const res_ci = await axiosInstance.get(
        `${serverIP}/loan/loansbyofficer/${data.id}`
      );
      console.log(res_ci.data)
      setLoans(res_ci.data);
      setFilteredLoans(res_ci.data);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return (
      <div className="h-28 flex items-center justify-center bg-gray-100">
        <div className="flex flex-col gap-3 items-center justify-center">
          <PulseLoader size={30} color="orange" loading="true" />
          <div style={{ color: "#FFA500" }}>Loading ...</div>
        </div>
      </div>
    );
  }

  const redirectPage = async (custId, loanId) => {
    try {
      const res = await axiosInstance.get(`${serverIP}/loan/loans/${loanId}`);
      dispatch(setTargetLoan({ targetLoan: res.data }));

      const cusres = await axiosInstance.get(
        `${serverIP}/customer/customers/${custId}`
      );
      dispatch(setTargetClient({ client: cusres.data }));

      navigate(`/loanonofficer/${custId}/${loanId}`);
    } catch (error) {
      console.error("Error in redirecting:", error);
    }
  };

  const isValidReport = FinanceItems => {
    let res = false
    if (FinanceItems.length == 0) {
      res = false
    } else if (FinanceItems[35]?.markValue >= 0) {
      res = false
    } else if (FinanceItems[36]?.markValue == 0) {
      res = false
    } else if (FinanceItems.some(x => x.category_id === 6 && x.markValue !== 0)) { // Expected income
      res = true
    } else if (FinanceItems.some(x => x.category_id === 9 && x.markValue !== 0)) { // Capital
      res = true
    } else {
      res = true
    }
    return res
  }

  return (
    <div>
      <div className="row">

        <Modal
          show={showTip}
          onHide={() => setShowTip(false)}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>ግምገማ </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            ግምገማው እንዲሰራ እንደ ቅድመ ሁኔታ መግባት ያለባቸው መረጃዎች 
            <ul>
              <li>
                1. Expected Income From ከሚለው ምድብ አንድ መረጃ
                <ul className="px-4">
                  <li>
                    a.	ለምሳሌ From product or service sale's = 240,000
                  </li>
                </ul>
              </li>
              <li>
                2.	Capital ከሚለው ምድብ አንድ መረጃ
                <ul className="px-4">
                  <li>
                    a.	ለምሳሌ shares or other related bounds = 250,000
                  </li>
                </ul>
              </li>
              <li>
                3.	Expected Income From ከሚለው ምድብ አንድ መረጃ
                <ul className="px-4">
                  <li>
                    a.	ለምሳሌ From product/service sale's = 240,000
                  </li>
                </ul>
              </li>
            </ul>
          </Modal.Body>
        </Modal>

        <div className="col-sm-12">
          <div className='flex justify-between p-1 bg-slate-300 rounded-md'>
            <input type='text' className='form-control' placeholder='search'
              onChange={e => setFilteredLoans(loans.filter(item =>
              (item?.customer?.amDisplayName.toLocaleLowerCase().includes(e.target.value.toLocaleLowerCase()) ||
                item?.customer?.entityExternalId.toString().includes(e.target.value))
              ))} />
          </div>
          <div className='mt-2 p-1 bg-slate-200'>
            <DataTable columns={columns} data={filteredLoans} pagination> </DataTable>
          </div>
        </div>


      </div>
    </div>
  );
}

export default MyLoans