import React, { useRef, useEffect, useState } from 'react';
import { Accordion, Modal, Badge, Card, ListGroup, Alert } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { FaEye, FaFileAlt, FaPhone, FaPrint, FaUser } from 'react-icons/fa';
import { firstC, fifthC } from '../Common/modules/NewFiveCSums';

import { setTargetClient, setTargetLoan } from '../Common/redux/systemLookups';
import axiosInstance from '../axiosInstance';

import {
    Sum_Cogs,
    Sum_Operating_Expenses,
    Sum_Interest_Expenses,
    Sum_Tax_Expenses,
    Sum_Personal_Expenses,
    Sum_Incomes,
    Sum_Assets,
    Sum_Liabilities,
    Sum_Capitals,
    Monthly_Basic_Expenses,
    calculateIRR,
    DebtServiceCoverageRatioNew,
    LiquidityRatio,
    NetWorth,
    Debt_To_Asset_Ratio,
    Loan_To_Value_Ratio,
    CapitalEvaluation,
    CharacterEvaluation,
    Debt_To_Income_Ratio,
    min_dir_dscr_irr,
    dir_vs_dscd,
    finalAbilityToPay,
    collateralSum
} from "../Common/modules/NewFiveCSums";
import EvaluationKeyValue from './EvaluationKeyValue';


function LoanEvaluation() {
    const { serverIP } = useSelector(store => store.allsettings);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { customerId, loanId } = useParams();
    const { targetClient, targetLoan } = useSelector(store => store.systemLookups);
    const [viewObj, setViewObj] = useState({});
    const [show, setShow] = useState(false);
    const [totalMark, settotalMark] = useState(0);
    const [irrValue, setIrrValue] = useState(0);

    const [userMaritalStatusData, setUserMaritalStatusData] = useState([]);

    const uploadedStyle = 'bg-green-600 text-white flex justify-between';
    const notUploadedStyle = 'flex justify-between';

    const printRef = useRef();

    const handlePrint = () => {
        const printContents = printRef.current.innerHTML;
        const originalContents = document.body.innerHTML;
        document.body.innerHTML = printContents;
        window.print();
        document.body.innerHTML = originalContents;
    };

    const today = new Date();
    const formattedDate = today.toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric'
    });

    useEffect(() => {
        getLoan();
        getCustomer();
        setUserMaritalStatusData(targetClient.isMarried ? targetClient.marriedgeneralfiles : targetClient.singlegeneralfiles);
    }, []);

    useEffect(() => {
        let loanFinanceItems = targetLoan?.loan_fonance_items;

        let abilityToPay = finalAbilityToPay(targetLoan, loanFinanceItems) > 100 ? 25 : finalAbilityToPay(targetLoan, loanFinanceItems) / 4;
        let conditionEvaluation = firstC(targetClient, targetLoan);
        let capitalEval = CapitalEvaluation(targetLoan) / 10;
        let CharacterEval = CharacterEvaluation(targetLoan);
        let collateralEval = fifthC(targetLoan);

        let irr_Value = calculateIRR([targetLoan?.loan_fonance_items[35]?.markValue, targetLoan?.loan_fonance_items[36]?.markValue]);
        setIrrValue(Number(irr_Value).toFixed(2));

        console.log(`Type of Collateral Eval = ${typeof (collateralEval)}`)

        settotalMark(0);
        settotalMark(
            conditionEvaluation +
            capitalEval +
            abilityToPay +
            CharacterEval + collateralEval
        );
    }, [targetClient, targetLoan]);



    const getLoan = async () => {
        await axiosInstance
            .get(`${serverIP}/loan/loans/${loanId}`)
            .then(res => {
                console.log("LOAN")
                console.log(res.data)
                dispatch(setTargetLoan({ targetLoan: res.data }));
            })
            .catch(err => { });
    };

    const getCustomer = async () => {
        await axiosInstance
            .get(`${serverIP}/customer/customers/${customerId}`)
            .then(res => {
                dispatch(setTargetClient({ client: res.data }));
            })
            .catch(err => { });
    };


    // const redirectPage = async (custId, loanId) => {
    //     try {
    //         await getLoan(loanId);
    //         await getCustomer(custId);
    //         navigate(`/creditanalysis/${custId}/${loanId}`);
    //     } catch (error) {
    //         console.error('Error in redirecting:', error);
    //     }
    // };


    return (
        <div className="row">
            {/* Modal Show Start  */}
            <Modal size="lg" show={show} onHide={() => setShow(false)}>
                <Modal.Header closeButton>
                    <Modal.Title> {viewObj.amDesc} </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <img src={serverIP + viewObj.fileUrl} style={{ width: '100%', height: '100%' }} />
                </Modal.Body>
            </Modal>
            {/* Modal Show End  */}


            <div className="col-sm-8" ref={printRef}>
                {/* General Info Start  */}
                <div className="bg-white p-4 rounded-lg shadow-md mb-1">
                    <div className="grid grid-cols-2 gap-1">
                        <div>
                            <p className="font-normal">{`Name: ${targetClient.amDisplayName}`}</p>
                            <p className="font-normal">{`Address: ${targetClient.regionId}, ${targetClient.zoneId}, ${targetClient.woredaId}`}</p>
                            <p className="font-normal">{`Member Since: ${targetClient.memberSince}`}</p>
                            <p className="font-normal"> {`Today's Date: ${formattedDate}`} </p>
                            <p className="font-normal"> Employer:  ----- </p>
                            <p className="font-normal"> Position:  ---- </p>
                            <p className="font-normal">{`Term (months): ${targetLoan.numberOfRepayments} Months`}</p>
                            <p className="font-normal">{`Interegst Rate: ${targetLoan.annualInterestRate} %`}</p>

                        </div>
                        <div>
                            <p className="font-normal">{`Account #: ${targetClient.entityAccountNo} `}</p>
                            <p className="font-normal">{`External Acount: ${targetClient.entityExternalId}`}</p>
                            {/* <p className="font-normal"> Use:  --120-- </p>
                            <p className="font-normal"> Type :  --0.1%--</p> */}
                            <p className="font-normal">Productive (G), provident(I) : ${(targetLoan.r_totalDueForPeriod + 5)?.toLocaleString()} </p>
                            <p className="font-normal"> How Long :  ----</p>
                            <p className="font-normal">{`Work Telephone: ${targetClient.mobileNo}`}</p>
                            <p className="font-normal">{`Monthly Payment: ${targetLoan.r_totalDueForPeriod?.toLocaleString()}`}</p>
                            <p className="font-normal">{`Collateral Value: ${collateralSum(targetLoan)?.toLocaleString()}`}</p>
                        </div>
                    </div>
                </div>
                {/* General Info End  */}


                <Accordion defaultActiveKey="open">

                    {/* Condition Evaluation Start  */}
                    <Accordion.Item eventKey="open">
                        <Accordion.Header className="flex justify-end ">

                            <Badge>C1</Badge> - Condition Evaluation
                            <Badge>
                                {Number(firstC(targetClient, targetLoan) * 100 / 35).toFixed(2) + " %"}
                            </Badge>
                        </Accordion.Header>
                        <Accordion.Body className='p-0'>
                            {userMaritalStatusData.length > 0 && userMaritalStatusData.map((data) => {
                                return <>
                                    <EvaluationKeyValue thekey={data.amDesc} isodd={data.isUploaded} value={data.markValue} />
                                    {/* {data.isUploaded ? (
                                        <>
                                            {data.fileType === 'application/pdf' ? (
                                                <a target="_blank" href={serverIP + data.fileUrl} rel="noopener noreferrer">
                                                    <FaFileAlt />
                                                </a>
                                            ) : (
                                                <FaEye
                                                    onClick={() => {
                                                        setViewObj(el);
                                                        setShow(true);
                                                        console.log('set show clicked');
                                                    }}
                                                />
                                            )}
                                        </>
                                    ) : (
                                        <></>
                                    )} */}
                                </>
                            })}
                        </Accordion.Body>
                    </Accordion.Item>
                    {/* Condition Evaluation End  */}

                    {/* Capital Evaluation Start  */}
                    <Accordion.Item eventKey="open">
                        <Accordion.Header className="flex justify-end ">
                            <Badge>C2</Badge> - Capital Evaluation
                            <Badge>
                                {CapitalEvaluation(targetLoan) + " %"}
                            </Badge>
                        </Accordion.Header>
                        <Accordion.Body className='p-0'>
                            <ListGroup.Item as="li" className="d-flex p-0">
                                <div className="w-3/5 p-0">
                                    <ListGroup as="ol" className="p-0">
                                        <ListGroup.Item as="li" >
                                            <EvaluationKeyValue thekey="1. TOTAL ASSETS" isodd="True" value={Sum_Assets(targetLoan.loan_fonance_items)} />
                                            <EvaluationKeyValue thekey="Cash and Cash Equivalents (in bank or related institution)" value={targetLoan?.loan_fonance_items[26]?.markValue} />
                                            <EvaluationKeyValue thekey="Account Receivable" value={targetLoan?.loan_fonance_items[27]?.markValue} />
                                            <EvaluationKeyValue thekey="Inventory" value={targetLoan?.loan_fonance_items[28]?.markValue} />
                                            <EvaluationKeyValue thekey="Property, Plant, and Equipment:(fixed and mobile/ movable)" value={targetLoan?.loan_fonance_items[29]?.markValue} />
                                        </ListGroup.Item>
                                        <ListGroup.Item as="li" >
                                            <EvaluationKeyValue thekey="2. LIABILITIES" isodd="True" value={Sum_Liabilities(targetLoan.loan_fonance_items)} />
                                            <EvaluationKeyValue thekey="Accounts Payable" value={targetLoan?.loan_fonance_items[30]?.markValue} />
                                            <EvaluationKeyValue thekey="Short-Term Loans" value={targetLoan?.loan_fonance_items[31]?.markValue} />
                                            <EvaluationKeyValue thekey="Long-Term Loans" value={targetLoan?.loan_fonance_items[32]?.markValue} />
                                        </ListGroup.Item>
                                        <ListGroup.Item as="li" >
                                            <EvaluationKeyValue thekey="3. CAPITAL" isodd="True" value={Sum_Capitals(targetLoan.loan_fonance_items)} />
                                            <EvaluationKeyValue thekey="Shares or other related bounds" value={targetLoan?.loan_fonance_items[33]?.markValue} />
                                            <EvaluationKeyValue thekey="Retailed earnings" value={targetLoan?.loan_fonance_items[34]?.markValue} />
                                        </ListGroup.Item>
                                    </ListGroup>
                                </div>
                                <div class="w-2/5 p-1 bg-gray-200">
                                    <EvaluationKeyValue thekey="Liquidity Ratio" value={LiquidityRatio(targetLoan.loan_fonance_items)} />
                                    <EvaluationKeyValue thekey="" value={LiquidityRatio(targetLoan.loan_fonance_items) > 1 ? "10 %" : "5%"} isodd="True" />
                                    <EvaluationKeyValue thekey="Net Worth" value={NetWorth(targetLoan.loan_fonance_items)} />
                                    <EvaluationKeyValue thekey="" value={NetWorth(targetLoan.loan_fonance_items) > 0 ? "10%" : "0%"} isodd="True" />
                                    <EvaluationKeyValue thekey="Debt-to-Asset Ratio" value={Debt_To_Asset_Ratio(targetLoan.loan_fonance_items)} />
                                    <EvaluationKeyValue thekey="" value={Debt_To_Asset_Ratio(targetLoan.loan_fonance_items) < 0.5 ? "30%" : "10%"} isodd="True" />
                                    <EvaluationKeyValue thekey="Loan-to-value Ratio (LTV) " value={Loan_To_Value_Ratio(targetLoan)} />
                                    <EvaluationKeyValue thekey="" value={Loan_To_Value_Ratio(targetLoan) < 0.8 ? "50%" : "20%"} />
                                    <Alert className='mt-4'>
                                        {CapitalEvaluation(targetLoan) + " %"}
                                    </Alert>
                                </div>
                            </ListGroup.Item>

                        </Accordion.Body>
                    </Accordion.Item>
                    {/* Capital Evaluation End  */}

                    {/* Ability To Pay (Capacity) Evaluation Start  */}
                    <Accordion.Item eventKey="open">
                        <Accordion.Header className="flex justify-between ">
                            <Badge>C3</Badge> - Ability To Pay (Capacity) Evaluation
                            <Badge>
                                {finalAbilityToPay(targetLoan, targetLoan?.loan_fonance_items) > 100 ? "100 %" : finalAbilityToPay(targetLoan, targetLoan?.loan_fonance_items) / 4 + " %"}
                            </Badge>
                        </Accordion.Header>
                        <Accordion.Body className='p-0'>
                            <ListGroup as="ol">
                                <ListGroup.Item as="li" >
                                    <EvaluationKeyValue thekey="1. MONTHLY INCOME" isodd="True" value={Sum_Incomes(targetLoan.loan_fonance_items)} />
                                    <EvaluationKeyValue thekey="From product/service sale's" value={targetLoan?.loan_fonance_items[18]?.markValue} />
                                    <EvaluationKeyValue thekey="Commissions" value={targetLoan?.loan_fonance_items[19]?.markValue} />
                                    <EvaluationKeyValue thekey="Professiona Fees or salary" value={targetLoan?.loan_fonance_items[20]?.markValue} />
                                    <EvaluationKeyValue thekey="Profit from interest on investment" value={targetLoan?.loan_fonance_items[21]?.markValue} />
                                    <EvaluationKeyValue thekey="Rent from housing or commercial locale" value={targetLoan?.loan_fonance_items[22]?.markValue} />
                                    <EvaluationKeyValue thekey="Grants and Funding" value={targetLoan?.loan_fonance_items[23]?.markValue} />
                                    <EvaluationKeyValue thekey="Interest Income" value={targetLoan?.loan_fonance_items[24]?.markValue} />
                                </ListGroup.Item>

                                <ListGroup.Item as="li" className="d-flex">
                                    <div class="w-1/2 p-1">
                                        <EvaluationKeyValue thekey="2. MONTHLY EXPENDITURES" isodd="True" value="" />
                                        <ListGroup as="ol" >
                                            <ListGroup.Item as="li" >
                                                <EvaluationKeyValue thekey="2.1. BASIC" isodd="True" value={Monthly_Basic_Expenses(targetLoan.loan_fonance_items)} />
                                                <EvaluationKeyValue thekey="Cost of Goods Sold (COGS)" value={Sum_Cogs(targetLoan.loan_fonance_items)} />
                                                <EvaluationKeyValue thekey="Operating Expenses" value={Sum_Operating_Expenses(targetLoan.loan_fonance_items)} />
                                                <EvaluationKeyValue thekey="Interest Expenses" value={Sum_Interest_Expenses(targetLoan.loan_fonance_items)} />
                                                <EvaluationKeyValue thekey="Taxes Related Expenses" value={Sum_Tax_Expenses(targetLoan.loan_fonance_items)} />
                                                <EvaluationKeyValue thekey="Personal expenses" value={Sum_Personal_Expenses(targetLoan.loan_fonance_items)} />
                                                <EvaluationKeyValue thekey="Maintenance & related" value={targetLoan?.loan_fonance_items[37]?.markValue} />
                                            </ListGroup.Item>
                                        </ListGroup>

                                        <ListGroup as="ol">
                                            <ListGroup.Item as="li" >
                                                <EvaluationKeyValue thekey="2.2. FINANCIAL" isodd="True" value="0" />
                                                <EvaluationKeyValue thekey="Credit Union-Banks" value={targetLoan?.loan_fonance_items[38]?.markValue} />
                                                <EvaluationKeyValue thekey="Credit Cards" value={targetLoan?.loan_fonance_items[39]?.markValue} />
                                                <EvaluationKeyValue thekey="Initial investment" value={targetLoan?.loan_fonance_items[35]?.markValue} />
                                                <EvaluationKeyValue thekey="Sum of cash inflow" value={targetLoan?.loan_fonance_items[36]?.markValue} />
                                            </ListGroup.Item>
                                        </ListGroup>

                                        <ListGroup as="ol">
                                            <ListGroup.Item as="li" >
                                                <EvaluationKeyValue thekey="2.3. BASIC" isodd="True" value="5000" />
                                                <EvaluationKeyValue thekey="Housing Related to cleaning" value={targetLoan?.loan_fonance_items[40]?.markValue} />
                                                <EvaluationKeyValue thekey="Vehicle fuel" value={targetLoan?.loan_fonance_items[41]?.markValue} />
                                                <EvaluationKeyValue thekey="Other Expenses" value={targetLoan?.loan_fonance_items[42]?.markValue} />
                                            </ListGroup.Item>
                                        </ListGroup>
                                    </div>
                                    <div class="w-1/2 bg-gray-200 p-2">
                                        <div className="ms-2 me-auto">
                                            <div className="fw-bold">Business Revenue & Profit Evaluation</div>
                                            <EvaluationKeyValue thekey="Business Gross Income" value={Sum_Incomes(targetLoan.loan_fonance_items)} />
                                            <EvaluationKeyValue thekey="Total Expenses" value={Monthly_Basic_Expenses(targetLoan.loan_fonance_items) + 5000} />
                                            <EvaluationKeyValue thekey="Total Expenses With Margin (5%)" value={(Monthly_Basic_Expenses(targetLoan.loan_fonance_items) + 5000) * 105 / 100} />
                                            <EvaluationKeyValue thekey="Net Profit" value={Sum_Incomes(targetLoan.loan_fonance_items) - ((Monthly_Basic_Expenses(targetLoan.loan_fonance_items) + 5000) * 105 / 100)} />
                                            <EvaluationKeyValue thekey="Debt to income ratio" value={Debt_To_Income_Ratio(targetLoan)} />
                                            <EvaluationKeyValue thekey="Debt-service coverage Ratio " value={DebtServiceCoverageRatioNew(targetLoan, targetLoan.loan_fonance_items)} />
                                            <EvaluationKeyValue thekey="Internal Rate of return" value={irrValue} />
                                            {/* <EvaluationKeyValue thekey="" value={ } /> */}
                                            <div className='flex flex-col justify-center items-center text-xl font-bold m-4'>
                                                <div>DIR : {Number(4500 / Debt_To_Income_Ratio(targetLoan)).toFixed(2)}</div>
                                                <div>DSCR : {100 * DebtServiceCoverageRatioNew(targetLoan, targetLoan.loan_fonance_items) / 1} </div>
                                                <div>IRR : {10 * irrValue}</div>
                                            </div>

                                            <div className='flex gap-2 mb-2'>
                                                <div className='bg-white p-2 justify-center items-center w-1/2'>
                                                    {min_dir_dscr_irr(targetLoan, targetLoan.loan_fonance_items) + " %"}
                                                </div>
                                                <div className='bg-white p-2 justify-center items-center w-1/2'>
                                                    {dir_vs_dscd(targetLoan, targetLoan.loan_fonance_items) + " %"}
                                                </div>
                                            </div>

                                            <Alert>
                                                {finalAbilityToPay(targetLoan, targetLoan?.loan_fonance_items) > 100 ? "100 %" : finalAbilityToPay(targetLoan, targetLoan?.loan_fonance_items) / 4 + " %"}
                                            </Alert>
                                        </div>
                                    </div>
                                </ListGroup.Item>
                            </ListGroup>
                        </Accordion.Body>
                    </Accordion.Item>
                    {/* Ability To Pay (Capacity) Evaluation End  */}

                    {/* Character Evaluation Start  */}
                    <Accordion.Item eventKey="open">
                        <Accordion.Header className="flex justify-end ">
                            <Badge>C4</Badge> - Character Evaluation
                            <Badge>
                                {CharacterEvaluation(targetLoan) * 20 + " %"}
                            </Badge>
                        </Accordion.Header>
                        <Accordion.Body className='p-0'>
                            <ListGroup as="ol">
                                <ListGroup.Item as="li" >
                                    <EvaluationKeyValue thekey="1. WORK / BUSINESS OWNERSHIP" isodd="True" value="---" />
                                    <EvaluationKeyValue thekey="Self-Owned (S), Partnership (P), Complicated (C)" value={targetLoan?.businessOwnership} />
                                    <EvaluationKeyValue thekey="Time at previous Business (in months);with attachment" value={targetLoan?.previousBusinessTenureInMonths} />
                                    <EvaluationKeyValue thekey="Time in current residence or In AA  (in months)" value={targetLoan?.tenureInMonths} />
                                </ListGroup.Item>
                                <ListGroup.Item as="li" >
                                    <EvaluationKeyValue thekey="2. CREDIT AND SAVING EXPERIENCE" isodd="True" value="---" />
                                    <EvaluationKeyValue thekey="Good history in last loan or present loan: yes (Y), no (N)" value={targetLoan?.lastLoanGoodHistory ? "YES" : "NO"} />
                                    <EvaluationKeyValue thekey="Saving despline; Platinium(P), Gold(G), Siliver(S)" value={targetLoan?.savingdecipline} />
                                    <EvaluationKeyValue thekey="Reference In Amigos: good(g), average(a), poor(p)" value={targetLoan.referenceinamigos} />
                                </ListGroup.Item>
                            </ListGroup>
                        </Accordion.Body>
                    </Accordion.Item>
                    {/* Character Evaluation End  */}

                    {/* Collateral Evaluation Start  */}
                    <Accordion.Item eventKey="open">
                        <Accordion.Header className="flex justify-end ">
                            <Badge>C5</Badge> - Collateral Evaluation
                            <Badge>
                                {(fifthC(targetLoan) * 100) / 25 + " %"}
                            </Badge>
                        </Accordion.Header>
                        <Accordion.Body className='p-0'>
                            <ListGroup as="ul" style={{ padding: 0 }}>
                                <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start">
                                    የተያዘው ንብረት ጠቅላላ ግምት
                                    <span className="font-bold">{collateralSum(targetLoan)?.toLocaleString()}</span>
                                </ListGroup.Item>

                                <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start">
                                    የተፈቀደው የብድር መጠን
                                    <span className="font-bold">{targetLoan?.approvedPrincipal?.toLocaleString()}</span>
                                </ListGroup.Item>
                                <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start">
                                    የተያዠው ንብረት ለሚሰጠው ጠቅላላ ብድር (RATIO)
                                    <span className="font-bold">{Number((collateralSum(targetLoan) * 100) / targetLoan.approvedPrincipal).toFixed(2)}%</span>
                                </ListGroup.Item>
                            </ListGroup>
                        </Accordion.Body>
                    </Accordion.Item>
                    {/* Collateral Evaluation End  */}
                </Accordion>

                <div className="flex justify-around mt-2 text-lg bg-gray-400 p-2 rounded-lg">
                    <div>C1-{firstC(targetClient, targetLoan)?.toLocaleString()}/35</div>
                    <div>C2-{(CapitalEvaluation(targetLoan) / 10)?.toLocaleString()}/10</div>
                    <div>C3-{finalAbilityToPay(targetLoan, targetLoan?.loan_fonance_items) > 100 ? 25 : finalAbilityToPay(targetLoan, targetLoan?.loan_fonance_items) / 4}/25</div>
                    <div>C4-{CharacterEvaluation(targetLoan)?.toLocaleString()}/5 </div>
                    <div>C5-{fifthC(targetLoan)?.toLocaleString()}/25</div>
                </div>

                {totalMark < 76 ? (
                    <div className="flex justify-around mt-2 text-2xl border-3 border-red-600 p-2 rounded-lg">
                        <div className="text-red-700 font-bold">DENIED</div>
                        <div className="text-red-700 font-bold">{`Total Score : ${totalMark.toLocaleString()}/100`}</div>
                    </div>
                ) : (
                    <div className="flex justify-around mt-2 text-2xl border-3 border-green-600 p-2 rounded-lg">
                        <div className="text-green-600 font-bold">ACCEPTED</div>
                        <div className="text-green-600 font-bold">{`Total Score : ${totalMark.toLocaleString()}/100`}</div>
                    </div>
                )}
            </div>



            <div className="col-sm-4">
                <Card>
                    <Card.Header>Customer Detail </Card.Header>
                    <Card.Body>
                        <ListGroup as="ul" style={{ padding: 0 }}>
                            <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start">
                                <FaUser /> {targetClient?.amDisplayName}
                            </ListGroup.Item>
                            <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start">
                                <FaPhone /> {targetClient?.mobileNo}
                            </ListGroup.Item>
                        </ListGroup>
                    </Card.Body>
                </Card>
                <Card className="mt-2">
                    <Card.Header>Loan Detail </Card.Header>
                    <Card.Body>
                        <ListGroup as="ul" style={{ padding: 0 }}>
                            <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start">
                                <div>የብድር አይነት:</div> {targetLoan.loanProductName}
                            </ListGroup.Item>
                            <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start">
                                <div> የተፈቀደው የብድር መጠን፡</div> {targetLoan.approvedPrincipal}
                            </ListGroup.Item>
                            <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start">
                                <div> ጠቅላላ ቁጠባ፡</div> {targetLoan.r_totalSaving}
                            </ListGroup.Item>
                            <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start">
                                <div> ጠቅላላ ሼር፡</div> {targetLoan.r_totalShares}
                            </ListGroup.Item>
                        </ListGroup>
                    </Card.Body>
                </Card>
                <Card className="mt-2">
                    <Card.Header> ያልተሟሉ ብድር የመክፈል አቅም ማሳያ </Card.Header>
                    <Card.Body>
                        <ListGroup as="ul" style={{ padding: 0 }}>
                            {firstC(targetClient, targetLoan) < 35 ? (
                                <ListGroup.Item as="li" className="d-flex justify-content-between bg-red-500 align-items-start">
                                    C1 : Condition አልተሟላም
                                </ListGroup.Item>
                            ) : (
                                <></>
                            )}

                            {(min_dir_dscr_irr(targetLoan, targetLoan.loan_fonance_items) + dir_vs_dscd(targetLoan, targetLoan.loan_fonance_items)) < 13 ? (
                                <ListGroup.Item as="li" className="d-flex justify-content-between bg-red-500 align-items-start">
                                    C3 : የመክፈል አቅሙ ያነሰ ነው፡፡
                                </ListGroup.Item>
                            ) : (
                                <></>
                            )}

                            {fifthC(targetLoan) < 25 ? (
                                <ListGroup.Item as="li" className="justify-content-between bg-red-500 align-items-start">
                                    C5 : Collateral አልተሟላም
                                    <ul className="pl-10">
                                        <li>
                                            {`ቀሪ በቤት/በመኪና ዋስትና ሲገመት = ${(
                                                Number(targetLoan.approvedPrincipal) - Number(collateralSum(targetLoan))
                                            )?.toLocaleString()}`}
                                        </li>
                                        <li>
                                            {`ቀሪ በደመወዝ ዋስትና ሲገመት = ${(
                                                ((targetLoan.approvedPrincipal - collateralSum(targetLoan)) * 15) /
                                                100
                                            )?.toLocaleString()}`}
                                        </li>
                                    </ul>
                                </ListGroup.Item>
                            ) : (
                                <></>
                            )}
                        </ListGroup>
                    </Card.Body>
                </Card>

                <Card className="mt-2">
                    <Card.Header> ግመገማ </Card.Header>
                    <Card.Body>
                        <ListGroup as="ul" style={{ padding: 0 }}>
                            <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start">
                                {totalMark > 76 && totalMark < 85 && <>ጥሩ</>}
                                {totalMark > 86 && totalMark < 93 && <>በጣም ጥሩ</>}
                                {totalMark > 93 && <>እጅግ በጣም ጥሩ</>}
                                {totalMark < 76 && <>ደካማ</>}
                            </ListGroup.Item>
                        </ListGroup>
                    </Card.Body>
                </Card>

                <div className='flex justify-between mt-2' >
                    <button onClick={handlePrint} className="flex items-center px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-400" >
                        <FaPrint className="mr-2" /> Print
                    </button>
                    {/* <Link
                        onClick={() => { 
                            redirectPage(customerId, loanId); 
                        }}
                        className="font-medium text-blue-600 dark:text-blue-500 underline hover:underline text-xl"
                    >
                        Evaluation
                    </Link> */}
                </div>


            </div>
        </div>
    );
}


export default LoanEvaluation