import axiosInstance from '../axiosInstance';
import React, { useEffect, useState } from 'react';
import { Alert, Button, Modal, Table, ButtonGroup } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Formik, Form, Field, ErrorMessage } from 'formik';

import TextFormikInput from '../ReusableFormiks/TextFormikInput';
import EmployeeSchema from "./Schema/EmployeeSchema"
import TextInputTw from '../ReusableFormiks/TextInputTw';

import DataTable from 'react-data-table-component';
import { ToastContainer, toast } from 'react-toastify';

function Employees() {
  const [t, i18n] = useTranslation('global');
  const { serverIP } = useSelector(store => store.allsettings);
  const [emps, setEmps] = useState([]);

  const [modalAdd, setModalAdd] = useState(false);
  const [modalEdit, setModalEdit] = useState(false);
  const [modalActivate, setModalActivate] = useState(false);
  const [modalDeactivate, setModalDeactivate] = useState(false);
  const [modalResetPass, setModalResetPass] = useState(false);
  const [targetObj, setTargetObj] = useState({});

  const [username, setusername] = useState('');
  const [email, setemail] = useState('');
  const [first_name, setfirst_name] = useState('');
  const [last_name, setlast_name] = useState('');
  const [groups, setgroups] = useState('');
  const [filteredEmployees, setFilteredEmployees] = useState([]);

  const columns = [
    {
      name: "የመጠቀምያ ስም",
      selector: row => row.username,
      sortable: true
    },
    {
      name: "ሙሉ ስም",
      selector: row => row.first_name + ' ' + row.last_name,
      sortable: true
    },
    {
      name: "ኢሜይል",
      selector: row => row.email,
      sortable: true
    },
    {
      name: "የስራ ድርሻ",
      selector: row => row?.groups[0]?.slice(5, 20),
      sortable: true
    },
    {
      name: "Actions",
      cell: row => <ButtonGroup className="btn-sm">
        {row.is_active ? (
          <Button
            variant="danger"
            className="btn-sm"
            onClick={() => {
              setTargetObj(row);
              setModalDeactivate(true);
            }}
          >
            አሰናብት
          </Button>
        ) : (
          <Button
            variant="primary"
            className="btn-sm"
            onClick={() => {
              setTargetObj(row);
              setModalActivate(true);
            }}
          >
            ወደስራ መልስ
          </Button>
        )}

        <Button
          variant="warning"
          className="btn-sm"
          onClick={() => {
            setusername(row.username);
            setemail(row.email);
            setfirst_name(row.first_name);
            setlast_name(row.last_name);
            setgroups(row.groups[0]);
            setTargetObj(row);
            setModalEdit(true);
          }}
        >
          አስተካክል
        </Button>
        <Button
          variant="info"
          className="btn-sm"
          onClick={() => {
            setTargetObj(row);
            setModalResetPass(true);
          }}
        >
          reset
        </Button>
      </ButtonGroup>
    }
  ]

  const resetpassword = () => {
    console.log(targetObj.username)
    axiosInstance.post(`${serverIP}/lpsauth/reset-password`, {
      "username": targetObj.username
    }).then((res) => {
      console.log(res.data)
      toast.success('የአካውንት ፓስዎርድ ወደ Amigos@123 ተቀይሯል!');
      setModalResetPass(false);
    }).catch(err => {
      console.log(err)
      toast.error('የአካውንት ፓስዎርድ መቀየር አልተቻለም!');
    })
  }

  useEffect(() => {
    getAllEmployees();
  }, []);

  const getAllEmployees = () => {
    axiosInstance
      .get(`${serverIP}/lpsauth/allusers`)
      .then(res => {
        console.log(res.data);
        setEmps(res.data);
        setFilteredEmployees(res.data)
      })
      .catch(err => {
        console.log(err);
      });
  };

  const saveEmployee = values => {
    axiosInstance
      .post(`${serverIP}/lpsauth/register`, {
        username: values.username,
        email: values.email,
        password: 'P@55w0rd',
        first_name: values.first_name,
        last_name: values.last_name,
        groups: values.groups,
        is_active: 'true'
      })
      .then(res => {
        console.log(res.data);
        setModalAdd(false);
        getAllEmployees();
        toast.success('ሰራተኛ መረጃ ተቀምጧል! ');
      })
      .catch(err => {
        console.log(err);
        toast.error('ሰራተኛ መረጃ ማስቀመጥ አልተቻለም! ');
      });
  };

  const employeeUpdate = () => {
    axiosInstance
      .patch(`${serverIP}/lpsauth/user/${targetObj.id}`, {
        username: username,
        email: email,
        first_name: first_name,
        last_name: last_name
        // groups: groups,
      })
      .then(res => {
        console.log(res.data);
        setModalEdit(false);
        getAllEmployees();
        toast.success('ሰራተኛ መረጃ ተስተካክሏል!');
      })
      .catch(err => {
        console.log(err);
        toast.error('ሰራተኛ መረጃ አልተስተካከለም!');
      });
  };

  const activateAccount = () => {
    axiosInstance
      .patch(`${serverIP}/lpsauth/users/${targetObj.id}/activate-deactivate`, {
        is_active: true
      })
      .then(res => {
        console.log(res.data);
        toast.success('አካውንቱ Activate ተደርጓል!');
        setModalActivate(false);
        getAllEmployees();
      })
      .catch(err => {
        toast.error('አካውንቱ Activate አልተደረገም!');
        console.log(err);
      });
  };

  const deactivateAccount = () => {
    axiosInstance
      .patch(`${serverIP}/lpsauth/users/${targetObj.id}/activate-deactivate`, {
        is_active: false
      })
      .then(res => {
        console.log(res.data);
        setModalDeactivate(false)
        toast.success('አካውንቱ De-Activate ተደርጓል!');
        getAllEmployees();
      })
      .catch(err => {
        console.log(err);
        toast.error('አካውንቱ De-Activate አልተደረገም!');
      });
  };

  return (
    <div>
      {/* {JSON.stringify(emps)} */}
      {/* Modal ADD Start  */}
      <Modal show={modalAdd} onHide={() => setModalAdd(false)}>
        <Modal.Header closeButton>
          <Modal.Title>
            <div>ሰራተኛ መመዝገብያ </div>
          </Modal.Title>
        </Modal.Header>

        <Formik
          initialValues={{
            username: '',
            email: '',
            first_name: '',
            last_name: '',
            groups: ''
          }}
          validationSchema={EmployeeSchema}
          onSubmit={saveEmployee}
        >
          {({ errors }) => (
            <Form>
              <Modal.Body>
                <TextFormikInput name="username" placeholder="የተጠቃሚ ስም" />
                <TextFormikInput name="email" placeholder="ኢሜይል" />
                <TextFormikInput name="first_name" placeholder="ስም" />
                <TextFormikInput name="last_name" placeholder="የአባት ስም" />
                <div style={{ paddingTop: 5, paddingLeft: 5 }}>
                  <label>የስራ ድርሻ </label>
                  <Field
                    as="select"
                    name="groups"
                    className="form-control"
                    style={{
                      border: errors.groups ? '1px solid red' : ''
                    }}
                  >
                    <option value="">የስራ ድርሻ ይምረጡ </option>
                    <option value="ROLE_OFFICER">ROLE_OFFICER</option>
                    <option value="ROLE_TEAM_LEADER">ROLE_TEAM_LEADER</option>
                    <option value="ROLE_AUDITOR">ROLE_AUDITOR</option>
                    <option value="ROLE_PLANNER">ROLE_PLANNER</option>
                    <option value="ROLE_FINANCE">ROLE_FINANCE</option>
                  </Field>
                  <ErrorMessage style={{ color: 'red' }} name="groups" component="div" />
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button type="submit" variant="primary btn-sm">
                  አስቀምጥ
                </Button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
      {/* Modal Add End  */}

      {/* Modal Edit Start  */}
      <Modal show={modalEdit} onHide={() => setModalEdit(false)}>
        <Modal.Header closeButton>
          <Modal.Title>
            <div>ሰራተኛ ማስተካከያ </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <TextInputTw placeholder="የተጠቃሚ ስም" onChange={e => setusername(e.target.value)} value={username} />
          <TextInputTw placeholder="ኢሜይል" onChange={e => setemail(e.target.value)} value={email} />
          <TextInputTw placeholder="ስም" onChange={e => setfirst_name(e.target.value)} value={first_name} />
          <TextInputTw placeholder="የአባት ስም" onChange={e => setlast_name(e.target.value)} value={last_name} />
          <div className="py-2">
            የስራ ድርሻ
            <select className="form-control" disabled>
              <option selected={groups === 'ROLE_OFFICER'} value="ROLE_OFFICER">
                ROLE_OFFICER
              </option>
              <option selected={groups === 'ROLE_TEAM_LEADER'} value="ROLE_TEAM_LEADER">
                ROLE_TEAM_LEADER
              </option>
              <option selected={groups === 'ROLE_AUDITOR'} value="ROLE_AUDITOR">
                ROLE_AUDITOR
              </option>
              <option selected={groups === 'ROLE_PLANNER'} value="ROLE_PLANNER">
                ROLE_PLANNER
              </option>
              <option selected={groups === 'ROLE_FINANCE'} value="ROLE_FINANCE">
                ROLE_FINANCE
              </option>
            </select>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={() => employeeUpdate()}>
            {t('officer.edit')}
          </Button>
        </Modal.Footer>
      </Modal>
      {/* Modal Edit End  */}

      {/* Modal De-activate Start  */}
      <Modal show={modalActivate} onHide={() => setModalActivate(false)}>
        <Modal.Header closeButton>
          <Modal.Title>
            <div> ወደስራ መልስ </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>ሙሉ ስም: {targetObj.first_name + ' ' + targetObj.last_name}</div>
          {/* <div>የስራ ድርሻ፡ {targetObj?.groups[0]}</div> */}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={() => activateAccount()}>
            ወደስራ መልስ
          </Button>
        </Modal.Footer>
      </Modal>
      {/* Modal De-activate End  */}

      {/* Modal De-activate Start  */}
      <Modal show={modalDeactivate} onHide={() => setModalDeactivate(false)}>
        <Modal.Header closeButton>
          <Modal.Title>
            <div> ክስራ አሰናብት </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>ሙሉ ስም: {targetObj.first_name + ' ' + targetObj.last_name}</div>
          {/* <div>የስራ ድርሻ፡ {targetObj?.groups[0]}</div> */}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={() => deactivateAccount()}>
            አሰናብት
          </Button>
        </Modal.Footer>
      </Modal>
      {/* Modal De-activate End  */}


      {/* Modal Reset Password Start  */}
      <Modal show={modalResetPass} onHide={() => setModalResetPass(false)}>
        <Modal.Header closeButton>
          <Modal.Title>
            <div> ፓሥዎርድ ሪሴት ያድርጉ </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>ሙሉ ስም: {targetObj.first_name + ' ' + targetObj.last_name}</div>
          <div>የተጠቃሚ ስም: {targetObj.username}</div>
          <div>
            Password: <b>Amigos@123</b>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={() => resetpassword()}>
            ሪሴት አድርግ
          </Button>
        </Modal.Footer>
      </Modal>
      {/* Modal Reset Password End  */}


      <Alert className="px-3 flex flex-row justify-between py-1 items-center">
        <div> ሰራተኞች መመዝገብያ ቅጽ </div>
        <Button variant="primary" className="btn-sm" onClick={() => setModalAdd(true)}>
          {t('officer.add')}
        </Button>
      </Alert>


      <ToastContainer />

      <div className='flex justify-between p-1 bg-slate-300 rounded-md'>
        <input type='text' className='form-control' placeholder='search' onChange={e => setFilteredEmployees(emps.filter(item => (item?.first_name.toLocaleLowerCase().includes(e.target.value.toLocaleLowerCase()) || item?.username.toLocaleLowerCase().includes(e.target.value.toLocaleLowerCase()))))} />
      </div>
      <div className='mt-2 p-1 bg-slate-200'>
        <DataTable columns={columns} data={filteredEmployees} pagination> </DataTable>
      </div>

    </div>
  );
}

export default Employees;
