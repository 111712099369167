import * as Yup from "yup";

const CarSchema = Yup.object().shape({
  manufacturedYear: Yup.number().required("የተመረተበት ዓ.ም ያስገቡ"),
  chassisNumber: Yup.string().required("ቻሲስ ቁጥር ያስገቡ"),
  engineNumber: Yup.string().required("የኢንጅን ቁጥር ያስገቡ"),
  librebookid: Yup.string().required("የሊብሬ ቁጥር ያስገቡ"),
  carPlate: Yup.string().required("የመኪና ታርጋ ያስገቡ"),

  carCC: Yup.string().required("የመኪና ሲሲ ያስገቡ"),
  loan: Yup.string().required("loan is required"),
  manufacturedCountry: Yup.number().required("የተመረተበት አገር ይምረጡ"),
  model: Yup.number().required("የመኪና ሞዴል ያስገቡ"),
  insuranceValue: Yup.string().required("የኢንሹራንስ ግምት ያስገቡ"),

  regionId: Yup.number().required("ክልል ይምረጡ"),
  zoneId: Yup.number().required("ዞን ይምረጡ"),
  woredaId: Yup.number().required("ወረዳ ይምረጡ"),
  sanctionAddress: Yup.string().required("የእግድ አድራሻ ያስገቡ "),

  vehiclePerformance: Yup.string().required("የተሽከርካሪ ብቃት ያስገቡ "),
  garageValue: Yup.string().required("የጋራጅ  ግምት ያስገቡ"),
});

export default CarSchema;