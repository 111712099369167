import axiosInstance from '../axiosInstance';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ListGroup, Button, InputGroup, Modal, Form, Accordion, Table } from 'react-bootstrap';
import { FaEye, FaFileAlt } from 'react-icons/fa';

import { numberToWords } from '../Common/modules/EnNumToWord';
import { setTargetLoan } from '../Common/redux/systemLookups';
import EtCurrency from '../Common/modules/currency';
import { toast } from 'react-toastify';

function Loan({ targetLoan, targetClient, successnotify, errornotify }) {
  const { serverIP } = useSelector(store => store.allsettings);
  const { data } = useSelector(store => store.customer);
  const dispatch = useDispatch();

  const [multiplier, setMultiplier] = useState('');
  const [totalInterestPayment, settotalInterestPayment] = useState('');
  const [queueTime, setqueueTime] = useState(0);

  const [lastReturnDate, setlastReturnDate] = useState('');
  const [totalDueForPeriod, settotalDueForPeriod] = useState('');
  const [bankstateent, setbankstateent] = useState('');

  const [file, setFile] = useState(null);
  const [viewObj, setViewObj] = useState({});
  const [show, setShow] = useState(false);

  const [markValue, setmarkValue] = useState(0);
  const [thedatas, setthedatas] = useState([]);


  // character evaluation 
  const [lstbusinessOwnership, setlstBusinessOwnership] = useState([]);
  const [lstsavingDecipline, setlstSavingDecipline] = useState([]);
  const [lstAmigosReference, setlstAmigosReference] = useState([]);

  const [previousBusinessTenure, setPreviousBusinessTenure] = useState(0);
  const [currentTenure, setCurrentTenure] = useState(0);
  const [goodHistory, setGoodHistory] = useState('');

  const [businessOwnerships, setBusinessOwnerships] = useState('');
  const [savingDeciplines, setSavingDeciplines] = useState('');
  const [referenceInAmigoss, setReferenceInAmigoss] = useState('');

  {/* businessOwnership savingdecipline referenceinamigos */ }

  useEffect(() => {
    setMultiplier(targetLoan.multiplier);
    settotalInterestPayment(targetLoan.totalInterestPayment === 0 ? 0 : targetLoan.totalInterestPayment);
    setqueueTime(targetLoan.queueTime === 0 ? '' : targetLoan.queueTime);
    setlastReturnDate(targetLoan.expectedDisbursementDate);
    settotalDueForPeriod(targetLoan.totalDueForPeriod);
    setbankstateent(targetLoan.bankstateent);

    // character evaluation 
    getLstbusinessOwnership();
    getLstsavingDecipline();
    getLstAmigosReference()

    setPreviousBusinessTenure(targetLoan.previousBusinessTenureInMonths);
    setCurrentTenure(targetLoan.tenureInMonths);
    setGoodHistory(targetLoan.lastLoanGoodHistory);

    setBusinessOwnerships(targetLoan?.businessOwnership_id);
    setSavingDeciplines(targetLoan?.savingdecipline_id);
    setReferenceInAmigoss(targetLoan?.referenceinamigos_id);

  }, []);



  const getLstbusinessOwnership = () => {
    axiosInstance
      .get(`${serverIP}/ddbusinesstype/ddlbusinesstype`)
      .then(res => {
        setlstBusinessOwnership(res.data);
        console.log(res.data);
      })
      .catch(err => {
        console.log(err);
      });
  };

  const getLstsavingDecipline = () => {
    axiosInstance
      .get(`${serverIP}/ddlsavingdecipline/savingdecipline`)
      .then(res => {
        setlstSavingDecipline(res.data);
        console.log(res.data);
      })
      .catch(err => {
        console.log(err);
      });
  };

  const getLstAmigosReference = () => {
    axiosInstance
      .get(`${serverIP}/ddlreferenceinamigos/referenceinamigos`)
      .then(res => {
        setlstAmigosReference(res.data);
        console.log(res.data);
      })
      .catch(err => {
        console.log(err);
      });
  };

  useEffect(() => {
    const categoryMap = new Map();
    targetLoan?.loan_fonance_items?.forEach(item => {
      if (!categoryMap.has(item.category)) {
        categoryMap.set(item.category, []);
      }
      categoryMap.get(item.category).push(item);
    });

    const groupedData = Array.from(categoryMap, ([category, items]) => ({ category, items }));
    setthedatas(groupedData);
  }, [targetLoan]);

  const saveChanges = () => {
    axiosInstance
      .patch(`${serverIP}/loan/loans/${targetLoan.id}`, {
        multiplier,
        totalInterestPayment,
        queueTime,
        lastReturnDate,
        totalDueForPeriod,
        bankstateent,
        queueDate: addBusinessDays(queueTime),
        previousBusinessTenureInMonths: previousBusinessTenure,
        lastLoanGoodHistory: goodHistory,
        tenureInMonths: currentTenure,
        businessOwnership: businessOwnerships,
        savingdecipline: savingDeciplines,
        referenceinamigos: referenceInAmigoss
      })
      .then(res => {
        successnotify('የብድር መረጃ ተስተካክሏል!');
        getLoan();
      })
      .catch(err => {
        console.log(err);
        errornotify("የብድር መረጃ አልተስተካከለም");
      });
    // console.log("All changes made");
    // console.log(businessOwnerships);
    // console.log(savingDeciplines);
    // console.log(referenceInAmigoss);
  };

  const getLoan = () => {
    axiosInstance
      .get(`${serverIP}/loan/loans/${targetLoan.id}`)
      .then(res => {
        dispatch(
          setTargetLoan({
            targetLoan: res.data
          })
        );
      })
      .catch(err => {
        console.log(err);
      });
  };

  const loadData = async () => {
    try {
      const fndatas = await axiosInstance.get(`${serverIP}/loanfinancedata/loanfinancedatas`);
      const transformedArray = fndatas?.data.map(item => ({
        amDesc: item.amName,
        category: item.category,
        loan: targetLoan.id,
        dataid: item.id,
      }));

      await axiosInstance.post(`${serverIP}/loanfinanceitems/loanfinanceitemss/bulkinsert`, transformedArray);
    } catch (error) {
    } finally {
      getLoan();
    }
  };

  function addBusinessDays(daysToAdd) {
    let currentDate = new Date();
    let addedDays = 0;

    while (addedDays < daysToAdd) {
      currentDate.setDate(currentDate.getDate() + 1);
      if (currentDate.getDay() !== 0 && currentDate.getDay() !== 6) {
        addedDays++;
      }
    }
    return currentDate.toISOString().split('T')[0];
  }

  function handleUpload(fileid) {
    console.log(file);
    if (!file) {
      errornotify("እባክዎ ፋይል ያያይዙ");
      return;
    }

    const fd = new FormData();
    fd.append('file', file);
    fd.append('fileType', file.type);
    fd.append('fileUrl', file);
    fd.append('isUploaded', true);

    axiosInstance
      .patch(`${serverIP}/loan/loans/${fileid}`, fd, {
        headers: {
          'Custom-Header': 'value'
        }
      })
      .then(res => {
        successnotify("መረጃ ተሰቅሏል!!");
        getLoan();
      })
      .catch(err => {
        errornotify("መረጃ በትክክል አልተሰቀለም!!");
        console.log(err);
      });
  }

  const saveAmountInBirr = (itemid, dataid) => {
    let initialInvestment = targetLoan?.loan_fonance_items[35].markValue

    if (dataid == 36) {
      if (markValue >= 0) {
        toast.error("ዜሮ እና ከዜሮ በላይ አይፈቀድም")
      } else {
        validSave(itemid);
      }
    } else if (dataid == 37) {
      if (markValue <= 0) {
        return { isValid: false, message: "Cash flow must be a positive number." };
      }

      // Calculate IRR
      const irr = Math.pow(markValue / Math.abs(initialInvestment), 1) - 1;

      if (irr <= 0 || !isFinite(irr)) {
        // return { isValid: false, message: "Cash flow does not produce a valid percentage IRR." };
        toast.error("በተሰጠው Cash flow, IRR መቶኛ ማውጣት አይቻልም.")
      } else {
        validSave(itemid);
      }
    } else {
      validSave(itemid);
    }

  };

  const validSave = (itemid) => {
    axiosInstance
      .patch(`${serverIP}/loanfinanceitems/loanfinanceitemss/${itemid}`, {
        markValue: markValue
      })
      .then(res => {
        successnotify("መረጃ ተስተካክሏል !!");
        getLoan();
      })
      .catch(err => {
        errornotify("መረጃ አልተስተካከለም!!");
        console.log(err);
      });
  }

  return (
    <div>
      {/* Modal Show Start  */}
      <Modal size="lg" show={show} onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title> {viewObj.amDesc} </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <img src={serverIP + viewObj.fileUrl} style={{ width: '100%', height: '100%' }} />
        </Modal.Body>
      </Modal>
      {/* Modal Show End  */}

      <div className="card">
        <div className="card-body flex flex-wrap items-start gap-1 w-full">
          {/* Select Input 1 */}
          <div className="flex flex-col w-1/6">
            <label htmlFor="select1" className="text-gray-700 mb-1">Business Ownership</label>
            <select id="select1" className="border border-gray-300 rounded-md p-2" onChange={(e) => {
              setBusinessOwnerships(e.target.value)
            }}>
              {lstbusinessOwnership.map(ln => {
                return (
                  <option key={ln.id} value={ln.id} selected={ln.id === targetLoan?.businessOwnership_id} >
                    {ln.amName}
                  </option>
                );
              })}
            </select>
          </div>
          {/* Select Input 2 */}
          <div className="flex flex-col w-1/6">
            <label htmlFor="select2" className="text-gray-700 mb-1">Saving Decipline</label>
            <select id="select2" className="border border-gray-300 rounded-md p-2" onChange={e => setSavingDeciplines(e.target.value)} >
              {lstsavingDecipline.map(ln => {
                return (
                  <option key={ln.id} value={ln.id} selected={ln.id === targetLoan?.savingdecipline_id} >
                    {ln.amName}
                  </option>
                );
              })}
            </select>
          </div>
          {/* Select Input 3 */}
          <div className="flex flex-col w-1/6">
            <label htmlFor="select3" className="text-gray-700 mb-1">Reference In Amigos</label>
            <select id="select3" className="border border-gray-300 rounded-md p-2" onChange={e => setReferenceInAmigoss(e.target.value)} >
              {lstAmigosReference.map(ln => {
                return (
                  <option key={ln.id} value={ln.id} selected={ln.id === targetLoan?.referenceinamigos_id} >
                    {ln.amName}
                  </option>
                );
              })}
            </select>
          </div>
          {/* Text Input */}
          <div className="flex flex-col w-1/6">
            <label htmlFor="textInput" className="text-gray-700 mb-1">Previous Business Tenure</label>
            <input value={previousBusinessTenure}
              type="text"
              className="form-control"
              onChange={e => setPreviousBusinessTenure(e.target.value)} />
          </div>
          {/* Text Input */}
          <div className="flex flex-col w-1/6">
            <label htmlFor="textInput" className="text-gray-700 mb-1">AA Residence in Months</label>
            <input value={currentTenure}
              type="text"
              className="form-control"
              onChange={e => setCurrentTenure(e.target.value)} />
          </div>
          {/* Checkbox Input */}
          <div className="flex flex-col w-1/7 ">
            <label className="text-gray-700 mb-1">Good History</label>
            <div className="flex items-center">
              <input type="checkbox" checked={goodHistory}
                onChange={e => {
                  setGoodHistory(e.target.checked);
                }} className="mr-2" />
              <label htmlFor="checkbox" className="text-gray-700"  >Yes</label>
            </div>
          </div>
        </div>
      </div>


      <div className="row">
        <div className="col-sm-12">
          <ListGroup>
            <ListGroup.Item className="d-flex align-items-start">
              ቁጠባ:
              {' ' + targetLoan?.totalSaving?.toLocaleString('am-ET', EtCurrency)}
              &nbsp; &nbsp;
              <b> {numberToWords(targetLoan?.r_totalSaving)} </b>
            </ListGroup.Item>
            <ListGroup.Item className="d-flex align-items-start">
              {/* <ListGroup.Item className="d-flex justify-content-between align-items-start"> */}
              አክስዮን:
              {' ' + targetLoan?.totalShares?.toLocaleString('am-ET', EtCurrency)}
              &nbsp; &nbsp;
              <b> {numberToWords(targetLoan?.totalShares)} </b>
            </ListGroup.Item>
            <ListGroup.Item className="d-flex align-items-start">
              የተፈቀድው የብድር መጠን : &nbsp;
              {targetLoan.approvedPrincipal?.toLocaleString('am-ET', EtCurrency)}
              &nbsp; &nbsp;
              <b>{numberToWords(targetLoan?.approvedPrincipal)}</b>
            </ListGroup.Item>
          </ListGroup>
        </div>
      </div>

      <div className="bg-gray-500 rounded-xl py-1 px-3 flex flex-row justify-between text-white">
        <div>{`Product: ${targetLoan.loanProductName}`}</div>
        <div>{`Purpose: ${targetLoan.loanPurposeName}`}</div>
      </div>

      {/* Container Start */}
      <div className="row">
        <div className="col-sm-8">
          <div
            style={{
              backgroundColor: 'white',
              borderRadius: 5
            }}
          >
            <div className="row" style={{ paddingTop: 1 }}>
              <div className="input-group">
                <span className="input-group-text"> የቁጠባ ማባዥ </span>
                <input
                  className="form-control"
                  type="number"
                  value={multiplier}
                  onChange={e => setMultiplier(Number(e.target.value))}
                  placeholder="የቁጠባ ማባዥ"
                />
              </div>
            </div>
            <div className="row" style={{ paddingTop: 1 }}>
              <div className="input-group">
                <span className="input-group-text">ጠቅላላ የወለድ መጠን </span>
                <input
                  className="form-control"
                  type="number"
                  value={totalInterestPayment}
                  onChange={e => settotalInterestPayment(Number(e.target.value))}
                  placeholder="ጠቅላላ የወለድ መጠን"
                />
              </div>
            </div>

            <div className="input-group mb-1" style={{ paddingTop: 1 }}>
              <div className="input-group-prepend">
                <span className="input-group-text">የብድር ሂደት ለመጀመር የወረፋ ግዜ መጠበቅያ</span>
              </div>
              <input
                className="form-control"
                type="number"
                value={queueTime}
                onChange={e => {
                  setqueueTime(e.target.value);
                  console.log(e.target.value);
                }}
                placeholder="የብድር ሂደት ለመጀመር የወረፋ ግዜ መጠበቅያ"
              />
              <div className="input-group-append">
                <span className="input-group-text">{targetLoan.queueDate}</span>
              </div>
            </div>

            <div className="row" style={{ paddingTop: 1 }}>
              <div className="input-group">
                <span className="input-group-text">ብድሩ ተመልሶ የሚያልቅበት ቀን </span>
                <input
                  className="form-control"
                  type="date"
                  value={lastReturnDate}
                  onChange={e => setlastReturnDate(e.target.value)}
                  placeholder="ብድሩ ተመልሶ የሚያልቅበት ቀን "
                />
              </div>
            </div>

            <div className="row" style={{ paddingTop: 1 }}>
              <div className="input-group">
                <span className="input-group-text"> ወርሀዊ ክፍያ </span>
                <input
                  className="form-control"
                  type="text"
                  value={totalDueForPeriod}
                  onChange={e => settotalDueForPeriod(e.target.value)}
                  placeholder="ወርሀዊ ክፍያ "
                />
              </div>
            </div>

            <div className="row" style={{ paddingTop: 1 }}>
              <div className="input-group">
                <span className="input-group-text"> ባንክ ስቴትመንት </span>
                <input
                  className="form-control"
                  type="text"
                  value={bankstateent}
                  onChange={e => setbankstateent(e.target.value)}
                  placeholder="ባንክ ስቴትመንት "
                />
              </div>
            </div>
          </div>
        </div>
        <div className="col-sm-4">
          <ListGroup className="mt-2">
            <ListGroup.Item>{`ሰርቪስ ቻርጅ: ${targetLoan.flatServiceCharge?.toLocaleString()}`}</ListGroup.Item>
            <ListGroup.Item>{`ላይፍ ኢንሹራንስ: ${targetLoan.flatLifeInsurance?.toLocaleString()}`}</ListGroup.Item>
            <ListGroup.Item>{`የወለድ ብዜት: ${targetLoan.annualInterestRate?.toLocaleString()} %`}</ListGroup.Item>

            {targetLoan.isUploaded ? (
              <ListGroup.Item className="bg-green-600 text-white flex justify-between">
                <div>አፕሎድ ተደርጓል</div>
                {targetLoan.fileType === 'application/pdf' ? (
                  <a target="_blank" href={serverIP + targetLoan.fileUrl} rel="noopener noreferrer">
                    <FaFileAlt />
                  </a>
                ) : (
                  <FaEye
                    onClick={() => {
                      setViewObj(targetLoan);
                      setShow(true);
                      console.log('set show clicked');
                    }}
                  />
                )}
              </ListGroup.Item>
            ) : (
              <ListGroup.Item className="bg-red-300 text-white font-bold">ስሊፕ አፕሎድ አልተደረገም</ListGroup.Item>
            )}
            <InputGroup style={{ paddingTop: 0 }}>
              <input
                onChange={e => {
                  setFile(e.target.files[0]);
                  console.log(e.target.files[0]);
                }}
                type="file"
                className="form-control"
              />
              <Button disabled={targetLoan?.loanstatus?.id !== 1} variant="outline-primary" onClick={() => handleUpload(targetLoan.id)}>
                {!targetLoan.isUploaded ? <>ጫን</> : <>አስተካክል</>}
              </Button>
            </InputGroup>
            <InputGroup className='flex justify-end'>
              <Button disabled={targetLoan?.loanstatus?.id !== 1} className="btn-warning btn-sm" onClick={saveChanges}>
                አስተካክል
              </Button>
              {thedatas.length == 0 && <Button className="btn-sm" onClick={() => loadData()}>ሳብ</Button>}
            </InputGroup>
          </ListGroup>
        </div>
      </div>
      {/* Container end  */}

      <Accordion defaultActiveKey="1" className='border bg-blue-200 border-blue-500 rounded-md'>
        {thedatas.length > 0 &&
          thedatas.map(thedata => {
            return (
              <Accordion.Item eventKey={thedata.category} className="bg-blue-200">
                <Accordion.Header>{thedata.category}</Accordion.Header>
                <Accordion.Body className='p-1'>
                  <Table striped bordered hover style={{ margin: 1 }}>
                    <thead>
                      <tr>
                        <th> የፋይል ስም </th>
                        <th> እይ </th>
                        <th> አስፈላጊነት </th>
                        <th> የብር መጠን </th>
                      </tr>
                    </thead>
                    <tbody>
                      {thedata?.items?.length > 0 &&
                        thedata?.items?.map(item => (
                          <tr key={item.id}>
                            <td>{`${item.amDesc}`}</td>
                            <td>
                              {item.isUploaded ? (
                                <>
                                  {item.fileType === 'application/pdf' ? (
                                    <a target="_blank" href={serverIP + item.fileUrl}><FaFileAlt /></a>
                                  ) : (
                                    <FaEye
                                      onClick={() => {
                                        setViewObj(item);
                                        setShow(true);
                                        console.log('set show clicked');
                                      }}
                                    />
                                  )}
                                </>
                              ) : (
                                <></>
                              )}
                            </td>
                            <td style={{ padding: 0 }}>
                              <div className="flex gap-2 p-1">
                                <InputGroup>
                                  <input
                                    onChange={e => {
                                      setFile(e.target.files[0]);
                                      console.log(e.target.files[0]);
                                    }}
                                    type="file"
                                    className="form-control"
                                  />
                                  <Button  disabled={targetLoan?.loanstatus?.id !== 1} variant="outline-primary" onClick={() => handleUpload(item.id)}>
                                    {!item.isUploaded ? <> ጫን </> : <>አዘምን</>}
                                  </Button>
                                </InputGroup>
                                {item.isUploaded && (
                                  <Button  disabled={targetLoan?.loanstatus?.id !== 1} variant="outline-danger btn-sm" onClick={() => handleDeleteFile(item.id)}>አጥፋ</Button>
                                )}
                              </div>
                            </td>
                            <td style={{ padding: 0 }}>
                              <InputGroup className="p-1">
                                <InputGroup.Text>{item?.markValue?.toLocaleString()}</InputGroup.Text>
                                <Form.Control type="number" onChange={e => setmarkValue(e.target.value)} />
                                <Button  disabled={targetLoan?.loanstatus?.id !== 1} variant="outline-secondary" onClick={() => saveAmountInBirr(item.id, item.dataid)} > Update </Button>
                              </InputGroup>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </Table>
                </Accordion.Body>
              </Accordion.Item>
            );
          })}
      </Accordion>
    </div>
  );
}


export default Loan